import {
  ChangeEvent,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Table, SIZE } from 'baseui/table-semantic';
import { Input } from 'baseui/input';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import { Button, KIND } from 'baseui/button';
import { Card } from 'baseui/card';
import Loader from 'components/Loader';
import {
  StatusItemType,
  TransactionsStatus,
  TransactionsType,
  TypesItemType,
} from 'types/MasterAccountTypes';
import PriceFormatter from 'utils/priceFormatter';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import {
  workerAccountTransactionsMorePagesSelector,
  workerAccountTransactionsPendingListSelector,
  workerAccountTransactionsSelector,
  workerAccountTransactionsAreFetchedSelector,
  fetchWorkerAccountTransactionsOnLoadMore,
  resetTransactions,
  fetchWorkerAccountTransactions,
} from 'store/slices/transactions';
import CellFormControl from 'components/CellFormControl';
import { DatePicker } from 'baseui/datepicker';
import { datePickerOverrides } from 'components/Form/AppDatePicker';
import { Select, Value } from 'baseui/select';
import { FetchWorkerAccountTransactionsPropsType, WorkerTransactionsValueType } from 'types/WorkerAccountTypes';
import { containerStyles } from 'screens/CommonHelpers';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import {
  fetchWorkerAccountWithBalance,
  workerAccountBalanceSelector,
  workerAccountPendingSelector,
  workerAccountWorkerSelector,
} from 'store/slices/workerAccount';
import Access, { AccessUnit } from 'components/Access/Access';
import { prevPageSelector } from 'store/slices/application';
import { InputCustomHTMLElement, OptionalDateOrDateArrayType } from 'types/CommonTypes';
import { emptyPlaceholder } from 'theme';
import WorkerAccountTransactionsDetailsModal from './WorkerAccountTransactionsDetailsModal';
import WorkerAccountTransactionsRecoverFundsModal from './WorkerAccountTransactionsRecoverFundsModal';

const recoverFundsBtnOverrides = {
  Root: {
    props: {
      id: 'Transactions-recover-funds',
    },
  },
};

let timer: any;

const WorkerAccountTransactions = () => {
  const { t } = useTranslation(['treasury', 'dateFormats']);
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const mountNodeRef = useRef(null);
  const startDateInputRef = useRef(null);
  const transactions = useAppSelector(workerAccountTransactionsSelector);
  const morePages = useAppSelector(workerAccountTransactionsMorePagesSelector);
  const pending = useAppSelector(workerAccountTransactionsPendingListSelector);
  const pendingWorker = useAppSelector(workerAccountPendingSelector);
  const areFetched = useAppSelector(workerAccountTransactionsAreFetchedSelector);
  const balance = useAppSelector(workerAccountBalanceSelector);
  const modals = useAppSelector(modalsSelector);
  const [clickedTransaction, setClickedTransaction] = useState<WorkerTransactionsValueType | undefined>();
  const [transactionStatus, setTransactionStatus] = useState<Value>();
  const [transactionType, setTransactionType] = useState<Value>();
  const [transactionNumber, setTransactionNumber] = useState('');
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [searchDebounce, setSearchDebounce] = useState<string | null>('');
  const [hasPerformedSearchTransactionNumber, setHasPerformedSearchTransactionNumber] = useState<boolean>(false);

  const prevPage = useAppSelector(prevPageSelector);

  const { id } = params;
  const datePickerDateFormat = t('dateFormats:date-picker.standard');
  const worker = useAppSelector(workerAccountWorkerSelector);
  const dateFormat = t('dateFormats:standard-with-time');

  const filteredTransactions = [...transactions]?.sort((a, b) => (moment(b?.transactionDate).unix()) - (moment(a?.transactionDate).unix()));

  const listOfStatuses: StatusItemType[] = [
    { label: t('treasury:transactionsStatus.PENDING'), value: TransactionsStatus.PENDING },
    { label: t('treasury:transactionsStatus.COMPLETED'), value: TransactionsStatus.COMPLETED },
    { label: t('treasury:transactionsStatus.DECLINED'), value: TransactionsStatus.DECLINED },
    { label: t('treasury:transactionsStatus.EXPIRED'), value: TransactionsStatus.EXPIRED },
  ];

  const listOfTypes: TypesItemType[] = [
    { label: t('treasury:transactionsType.DEBIT'), value: TransactionsType.DEBIT },
    { label: t('treasury:transactionsType.CREDIT'), value: TransactionsType.CREDIT },
  ];

  const handleStartDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    if (!date) {
      const startDateRef = startDateInputRef.current as any;
      setTimeout(() => {
        startDateRef.focusCalendar();
        startDateRef.close();
      }, 100);
    }
    if (date && !Array.isArray(date)) {
      setStartDate(date as Date);
      if (endDate && moment(date).isAfter(moment(endDate))) {
        setEndDate(undefined);
      }
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };

  const handleEndDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    if (date && !Array.isArray(date)) {
      setEndDate(date as Date);
    } else {
      setEndDate(undefined);
    }
  };

  const fetchTransactions = (moreTransactions: boolean = false) => {
    const transactionStatusEnum = (transactionStatus && transactionStatus[0]?.value?.toString()) || '';
    const transactionTypeEnum = (transactionType && transactionType[0]?.value?.toString()) || '';
    const filterParams: FetchWorkerAccountTransactionsPropsType = {
      // Subtraction/addition of one day due to defect D83955
      startDate: (startDate && moment(startDate).subtract(1, 'days').format('YYYY-MM-DD')) || '',
      endDate: (endDate && moment(endDate).add(1, 'days').format('YYYY-MM-DD')) || '',
      transactionStatus: transactionStatusEnum || '',
      transactionType: transactionTypeEnum || '',
      transactionNumber: hasPerformedSearchTransactionNumber ? transactionNumber : '',
    };

    if (id) {
      const workerAccountFilterParams: FetchWorkerAccountTransactionsPropsType = {
        workerID: id,
        ...filterParams,
      };
      if (moreTransactions === true) {
        dispatch(fetchWorkerAccountTransactionsOnLoadMore(workerAccountFilterParams));
      } else {
        dispatch(fetchWorkerAccountTransactions(workerAccountFilterParams));
      }
    }
  };

  const handleChangeTransactionNumber = (e: ChangeEvent<InputCustomHTMLElement>) => {
    setTransactionNumber(e.target.value);
    setHasPerformedSearchTransactionNumber(true);
  };

  const handleLoadMoreClick = () => {
    fetchTransactions(true);
  };

  const handleTransactionDateClick = (transaction: WorkerTransactionsValueType) => {
    setClickedTransaction(transaction);
    dispatch(setModal({
      name: ModalNames.TRANSACTION_HISTORY_DETAILS_MODAL,
      isOpen: true,
    }));
  };

  const handleRecoverFundsClick = () => {
    dispatch(setModal({
      name: ModalNames.RECOVER_FUNDS_MODAL,
      isOpen: true,
    }));
  };

  const refreshOnSave = () => {
    dispatch(fetchWorkerAccountWithBalance({
      workerID: id,
    }));
    fetchTransactions(false);
  };

  useEffect(() => {
    fetchTransactions(false);
  }, [startDate,
    endDate,
    transactionStatus,
    transactionType,
    searchDebounce]);

  useEffect(() => {
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      timer = undefined;
      setSearchDebounce(transactionNumber);
    }, 1000);
  }, [transactionNumber]);

  useEffect(() => {
    if (id) {
      dispatch(fetchWorkerAccountWithBalance({
        workerID: id,
      }));
    }

    return () => {
      dispatch(resetTransactions());
    };
  }, []);

  return (
    <div className={css(containerStyles)}>
      <CommonHeader
        title={`${t('transactionsHist')} - ${`${worker?.firstName} ${worker?.lastName}`} (${PriceFormatter().format(balance?.availableBalance || 0)})`}
        backTo={`${prevPage}`}
      >
        <Access oneOf={[AccessUnit.FBOManager]}>
          <Block
            alignItems="center"
            alignContent="end"
            justifyItems="end"
            display="inline-flex"
            justifyContent="flex-end"
            width="50%"
            minWidth="160px"
            height="72px"
          >
            <Button
              kind={KIND.secondary}
              overrides={recoverFundsBtnOverrides}
              onClick={handleRecoverFundsClick}
            >
              {t('treasury:transaction.recoverFunds')}
            </Button>
          </Block>
        </Access>
      </CommonHeader>
      <Loader active={(pending && !areFetched) || pendingWorker} />

      <Block
        marginTop="24px"
        marginBottom="16px"
      >
        <Grid
          align={ALIGNMENT.center}
        >
          <Cell span={12}>
            <b>
              {t('common:searchBy')}
            </b>
          </Cell>

          <CellFormControl
            cellSpan={4}
            label={t('treasury:transactionStatus.label')}
          >
            <Select
              size={SIZE.compact}
              clearable
              id="select"
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'select-worker-transaction-status',
                    id: 'worker-transaction-status-container',
                  },
                },
                Input: {
                  props: {
                    'data-testid': 'select-worker-transaction-status-input',
                    id: 'worker-transaction-status-input',
                  },
                },
              }}
              placeholder={t('treasury:transactionStatus.title')}
              type="select"
              options={listOfStatuses}
              labelKey="label"
              valueKey="value"
              onChange={({ value }) => setTransactionStatus(value)}
              value={transactionStatus as Value}
              maxDropdownHeight="300px"
              disabled={!!transactionNumber}
            />
          </CellFormControl>
          <CellFormControl
            cellSpan={4}
            label={t('treasury:transactionType.label')}
          >
            <Select
              size={SIZE.compact}
              clearable
              id="select"
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'select-worker-transaction-type',
                    id: 'worker-transaction-type-contanier',
                  },
                },
                Input: {
                  props: {
                    'data-testid': 'select-worker-transaction-type-input',
                    id: 'worker-transaction-type-input',
                  },
                },
              }}
              placeholder={t('treasury:transactionType.title')}
              type="select"
              options={listOfTypes}
              labelKey="label"
              valueKey="value"
              onChange={({ value }) => setTransactionType(value)}
              value={transactionType as Value}
              maxDropdownHeight="300px"
              disabled={!!transactionNumber}
            />
          </CellFormControl>
          <CellFormControl
            cellSpan={4}
            label={t('treasury:transactionStartDate.label')}
          >
            <DatePicker
              size={SIZE.compact}
              clearable
              ref={startDateInputRef}
              mountNode={mountNodeRef?.current as any}
              placeholder={t('treasury:transactionStartDate.title')}
              formatString={datePickerDateFormat}
              value={startDate}
              onChange={handleStartDateChange}
              overrides={{
                ...datePickerOverrides,
                Popover: {
                  props: {
                    id: 'transactions-start-date-container',
                  },
                },
              }}
              disabled={!!transactionNumber}
            />
          </CellFormControl>

          <div id="mount-calendar-here" ref={mountNodeRef} />

          <CellFormControl
            cellSpan={4}
            label={t('treasury:transactionEndDate.label')}
          >
            <DatePicker
              size={SIZE.compact}
              clearable
              placeholder={t('treasury:transactionEndDate.title')}
              formatString={datePickerDateFormat}
              value={endDate}
              onChange={handleEndDateChange}
              minDate={startDate}
              disabled={!startDate || !!transactionNumber}
              overrides={{
                ...datePickerOverrides,
                Popover: {
                  props: {
                    id: 'transactions-end-date-container',
                  },
                },
              }}
            />
          </CellFormControl>

          <CellFormControl
            cellSpan={4}
            label={t('treasury:transactionNumber.title')}
          >
            <Input
              size={SIZE.compact}
              clearable
              clearOnEscape
              autoComplete="off"
              name="search"
              type="text"
              id="transaction-history-search"
              onChange={handleChangeTransactionNumber}
              value={transactionNumber}
              placeholder={t('treasury:transactionNumber.title')}
              disabled={(transactionStatus && transactionStatus?.length > 0 && transactionStatus !== undefined)
                || (transactionType && transactionType?.length > 0 && transactionType !== undefined)
                || !!startDate
                || !!endDate}
            />
          </CellFormControl>
        </Grid>
      </Block>

      {transactions?.length > 0 && areFetched && (morePages || filteredTransactions?.length > 0)
        ? (
          <>
            <Block>
              <Grid>
                <Cell span={12}>
                  <Table
                    overrides={{
                      TableBodyRow: {
                        style: {
                          ':nth-child(even)': {
                            background: '#F5F5F5',
                          },
                        },
                      },
                      TableBodyCell: {
                        style: {
                          verticalAlign: 'middle',
                        },
                      },
                    }}
                    columns={[
                      t('treasury:table.transactionDate'),
                      t('treasury:table.transactionID'),
                      t('treasury:table.ledgerID'),
                      t('treasury:table.subelementID'),
                      t('treasury:table.amount'),
                      t('treasury:table.type'),
                      t('treasury:table.origin'),
                      t('treasury:table.destination'),
                      t('treasury:table.status'),
                    ]}
                    data={filteredTransactions?.map((transaction: WorkerTransactionsValueType) => {
                      const {
                        ledgerId,
                        subElementId,
                        origin,
                        id: transactionId,
                        transactionDate,
                        status,
                        type,
                        amount,
                        isoMessageType,
                      } = transaction;
                      let destination;
                      if (type === 'CREDIT' && isoMessageType?.startsWith('02')) {
                        destination = t('treasury:destination.typeCredit.isoMessage2xx');
                      } else if (type === 'CREDIT' && isoMessageType?.startsWith('04')) {
                        destination = t('treasury:destination.typeCredit.isoMessage4xx');
                      } else if (type === 'DEBIT' && isoMessageType?.startsWith('02')) {
                        destination = t('treasury:destination.typeDebit.isoMessage2xx');
                      } else if (type === 'P2P_DEBIT') {
                        destination = t('treasury:destination.typeP2P_Debit');
                      } else {
                        destination = 'UNKNOWN';
                      }
                      return [
                        <Button
                          overrides={{
                            Root: {
                              props: {
                                id: `WorkerAccountTransactions-transactionDate-btn-${transactionId}`,
                              },
                              style: {
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                                backgroundColor: 'transparent',
                              },
                            },
                          }}
                          kind={KIND.tertiary}
                          size="compact"
                          onClick={() => handleTransactionDateClick(transaction)}
                        >
                          {moment(transactionDate).format(dateFormat)}
                        </Button>,
                        transactionId,
                        ledgerId || emptyPlaceholder,
                        subElementId || emptyPlaceholder,
                        PriceFormatter().format(amount),
                        type,
                        origin?.replace(/_/g, ' '),
                        destination,
                        status,
                      ];
                    })}
                    size={SIZE.compact}
                  />
                </Cell>
              </Grid>
            </Block>

            <Block margin="10px 0 20px" display="flex">
              <Button
                overrides={{
                  Root: {
                    style: {
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    },
                    props: {
                      id: 'transaction-more-pages-button',
                    },
                  },
                  LoadingSpinner: {
                    style: {
                      borderRightColor: 'white',
                      borderTopColor: 'white',
                      borderLeftColor: 'white',
                    },
                  },
                }}
                isLoading={areFetched && pending}
                disabled={!morePages || pending}
                onClick={handleLoadMoreClick}
              >
                {t('morePages')}
              </Button>
            </Block>
          </>
        ) : (
          <Block
            marginTop="8px"
            marginBottom="8px"
          >
            <Grid>
              <Cell span={12}>
                <Card>
                  <p>{t('treasury:notFound')}</p>
                </Card>
              </Cell>
            </Grid>
          </Block>
        )}
      {checkIsModalOpen(modals, ModalNames.TRANSACTION_HISTORY_DETAILS_MODAL) && <WorkerAccountTransactionsDetailsModal transaction={clickedTransaction} />}
      {checkIsModalOpen(modals, ModalNames.RECOVER_FUNDS_MODAL) && <WorkerAccountTransactionsRecoverFundsModal refreshOnSave={refreshOnSave} />}

    </div>
  );
};

export default memo(WorkerAccountTransactions);
