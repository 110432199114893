import {
  createContext,
  memo,
  useEffect,
} from 'react';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, ALIGNMENT } from 'baseui/layout-grid';
import { Layer } from 'baseui/layer';
import { payGroupValidationSchema as validationSchema } from 'validation/addOrganizationSchema';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import { payGroupInitialValues } from 'initialValues/PayGroupsInitialValues';
import {
  organizationSelector,
  organizationConfigSelector,
} from 'store/slices/organizations';
import {
  fetchPayGroups,
  fetchPayGroup,
  createPayGroup,
  editPayGroup,
  resetSelectedPayGroup,
  payGroupCreatedSelector,
  selectedPayGroupSelector,
  payGroupPendingSelector,
} from 'store/slices/payGroups';
import { organizationConfigMapper } from 'dataMappers/organizationsDataMappers';
import { savePayGroupMapper, payGroupMapper } from 'dataMappers/payGroupsDataMapper';
import { errorSelector } from 'store/slices/error';
import { PayGroupValuesType, OrganizationFormPayGroupModalPropsType } from 'types/PayGroupTypes';
import AppModal from 'components/AppModal/AppModal';
import AppInput from 'components/Form/AppInput';
import AppSelect from 'components/Form/AppSelect';
import AppTimePicker from 'components/Form/AppTimePicker';
import Loader from 'components/Loader';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import AppTimezonePicker from 'components/Form/AppTimezonePicker';
import useIsFormChanged from 'hooks/useIsFormChanged';
import AppCheckbox from 'components/Form/AppCheckbox';
import AppTextarea from 'components/Form/AppTextarea';

export const PayGroupFormContext = createContext(
  {} as FormikState<PayGroupValuesType> & FormikHelpers<PayGroupValuesType> & FormikHandlers,
);

const OrganizationFormPayGroupModal = ({
  payGroupID,
}: OrganizationFormPayGroupModalPropsType) => {
  const dispatch = useAppDispatch();
  const modals = useAppSelector(modalsSelector);
  const payGroupSaved = useAppSelector(payGroupCreatedSelector);
  const payGroup = useAppSelector(selectedPayGroupSelector);
  const organizationConfig = useAppSelector(organizationConfigSelector);
  const { t } = useTranslation(['organizations', 'payGroups', 'errors', 'common']);
  const modalName = ModalNames.PAY_GROUP_FORM_MODAL;
  const isModalOpen = checkIsModalOpen(modals, modalName);
  const error = useAppSelector(errorSelector);
  const pending = useAppSelector(payGroupPendingSelector);
  const organization = useAppSelector(organizationSelector);
  const { id: organizationID } = organization || {};

  const initialValues = {
    ...payGroupInitialValues,
    ...(organizationConfig && organizationConfigMapper(organizationConfig)),
    deductionCode: organization && organization.deductionCode ? organization.deductionCode : '',
  };

  const modalTitle = payGroupID ? 'payGroups.editPayGroup' : 'payGroups.addPayGroup';

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const onSubmit = (
    values: PayGroupValuesType,
  ) => {
    organizationID
      && dispatch(
        payGroupID
          ? editPayGroup({
            organizationID,
            payGroupID,
            data: savePayGroupMapper(organizationID, { ...values, id: Number(payGroupID) }),
          })
          : createPayGroup({
            organizationID,
            data: savePayGroupMapper(organizationID, values),
          }),
      ).then(() => {
        setIsModalOpen(false);
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnMount: false,
    initialTouched: {
      payrollCutoffDayOffset: [{
        value: true,
      }],
    },
  });

  const daysOfWeek = [
    { value: 'SUNDAY', label: t('common:daysOfWeek.sunday') },
    { value: 'MONDAY', label: t('common:daysOfWeek.monday') },
    { value: 'TUESDAY', label: t('common:daysOfWeek.tuesday') },
    { value: 'WEDNESDAY', label: t('common:daysOfWeek.wednesday') },
    { value: 'THURSDAY', label: t('common:daysOfWeek.thursday') },
    { value: 'FRIDAY', label: t('common:daysOfWeek.friday') },
    { value: 'SATURDAY', label: t('common:daysOfWeek.saturday') },
  ];

  const dayOffsets = [
    { value: 0, label: `${t('common:days', { day: '0' })}` },
    { value: 1, label: `${t('common:day', { day: '1' })}` },
    { value: 2, label: `${t('common:days', { day: '2' })}` },
    { value: 3, label: `${t('common:days', { day: '3' })}` },
    { value: 4, label: `${t('common:days', { day: '4' })}` },
    { value: 5, label: `${t('common:days', { day: '5' })}` },
    { value: 6, label: `${t('common:days', { day: '6' })}` },
    { value: 7, label: `${t('common:days', { day: '7' })}` },
  ];

  const payCycles = [
    { value: 'WEEKLY', label: t('common:payCycles.weekly') },
    { value: 'BI_WEEKLY', label: t('common:payCycles.bi-weekly') },
  ];

  const {
    values,
    handleSubmit,
    resetForm,
    validateForm,
    isSubmitting,
    setSubmitting,
    setValues,
    isValid,
  } = formik;

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  const handleModalClose = () => {
    resetForm();
    setIsModalOpen(false);
  };

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  useEffect(() => {
    dispatch(resetSelectedPayGroup());
    return () => {
      dispatch(resetSelectedPayGroup());
    };
  }, []);

  useEffect(() => {
    if (payGroupSaved) {
      dispatch(fetchPayGroups({ organizationID }));
      resetForm();
      setSubmitting(false);
    }
  }, [payGroupSaved]);

  useEffect(() => {
    dispatch(resetSelectedPayGroup());
    setSubmitting(false);
  }, [isModalOpen]);

  useEffect(() => {
    isModalOpen && payGroupID && dispatch(fetchPayGroup({ organizationID, payGroupID }));
  }, [payGroupID, isModalOpen]);

  useEffect(() => {
    if (payGroup) {
      const convertedValues = payGroupMapper(payGroup);
      setValues(convertedValues);
      setDefaultValues(convertedValues);
    }
  }, [payGroup]);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
    }
  }, [error]);

  return (
    <Layer index={400}>
      <PayGroupFormContext.Provider value={formik}>
        <form>
          <AppModal
            confirm
            modal={modalName}
            title={t(modalTitle)}
            cancelBtnText={t('common:cancel')}
            onClose={handleModalClose}
            actionBtnText={t('common:save')}
            onAction={handleSubmitExtended}
            isCloseDisabled={isSubmitting}
            isActionDisabled={isSubmitting || !isValid || !isFormChanged}
          >
            <Loader active={pending} />

            <Grid
              gridColumns={12}
              gridMargins={20}
              align={ALIGNMENT.start}
            >
              <AppInput
                showStar
                name="payGroupName"
                inputProps={{
                  id: 'payGroupName',
                  autoComplete: 'off',
                }}
                formControlProps={{
                  htmlFor: 'payGroupName',
                }}
                label={t('payGroups:payGroup.details.name.label')}
                cellSpan={[12, 6]}
                context={PayGroupFormContext}
              />

              <AppSelect
                clearable
                showStar
                name="payCycle"
                label={t('payGroups:payGroup.details.payFrequencyRule.label')}
                options={payCycles}
                cellSpan={[12, 6]}
                selectProps={{ clearable: false }}
                context={PayGroupFormContext}
              />
            </Grid>

            <Grid
              gridColumns={12}
              gridMargins={20}
              align={ALIGNMENT.start}
            >
              <AppSelect
                showStar
                tooltipTitle={t('payGroups:payrollPeriodStartDay.tooltip.title')}
                tooltipContent={t('payGroups:payrollPeriodStartDay.tooltip.content')}
                name="payrollPeriodStartDay"
                cellSpan={[12, 6]}
                label={t('payGroups:payGroup.details.periodStartDay.label')}
                selectProps={{ clearable: false }}
                options={daysOfWeek}
                context={PayGroupFormContext}
              />
              <AppTimePicker
                showStar
                tooltipTitle={t('payGroups:businessDayStartTime.tooltip.title')}
                tooltipContent={t('payGroups:businessDayStartTime.tooltip.content')}
                name="businessDayStartTime"
                label={t('payGroups:payGroup.details.businessDayStartTime.label')}
                cellSpan={[12, 6]}
                timePickerProps={{ nullable: true }}
                context={PayGroupFormContext}
              />
            </Grid>

            <Grid
              gridColumns={12}
              gridMargins={20}
              align={ALIGNMENT.start}
            >
              <AppTimePicker
                showStar
                tooltipTitle={t('payGroups:ewaCutoffTime.tooltip.title')}
                tooltipContent={t('payGroups:ewaCutoffTime.tooltip.content')}
                name="ewaCutoffTime"
                cellSpan={[12, 6]}
                label={t('payGroups:payGroup.details.ewaCutoffTime.label')}
                timePickerProps={{ nullable: true }}
                context={PayGroupFormContext}
              />
              <AppSelect
                showStar
                tooltipTitle={t('payGroups:ewaCutoffDayOffset.tooltip.title')}
                tooltipContent={t('payGroups:ewaCutoffDayOffset.tooltip.content')}
                name="ewaCutoffDayOffset"
                cellSpan={[12, 6]}
                label={t('payGroups:payGroup.details.ewaCutoffDayOffset.label')}
                context={PayGroupFormContext}
                selectProps={{ clearable: false }}
                options={dayOffsets}
              />
            </Grid>

            <Grid
              gridColumns={12}
              gridMargins={20}
              align={ALIGNMENT.start}
            >
              <AppTimePicker
                showStar
                tooltipTitle={t('payGroups:tpoCutoffTime.tooltip.title')}
                tooltipContent={t('payGroups:tpoCutoffTime.tooltip.content')}
                name="tpoCutoffTime"
                cellSpan={[12, 6]}
                label={t('payGroups:payGroup.details.tpoCutoffTime.label')}
                timePickerProps={{ nullable: true }}
                context={PayGroupFormContext}
              />
              <AppSelect
                showStar
                tooltipTitle={t('payGroups:tpoCutoffDayOffset.tooltip.title')}
                tooltipContent={t('payGroups:tpoCutoffDayOffset.tooltip.content')}
                name="tpoCutoffDayOffset"
                cellSpan={[12, 6]}
                label={t('payGroups:payGroup.details.tpoCutoffDayOffset.label')}
                context={PayGroupFormContext}
                selectProps={{ clearable: false }}
                options={dayOffsets}
              />
            </Grid>

            <Grid
              gridColumns={12}
              gridMargins={20}
              align={ALIGNMENT.start}
            >
              <AppTimePicker
                showStar
                tooltipTitle={t('payGroups:payrollCutoffTime.tooltip.title')}
                tooltipContent={t('payGroups:payrollCutoffTime.tooltip.content')}
                name="payrollCutoffTime"
                cellSpan={[12, 6]}
                label={t('payGroups:payGroup.details.payrollCutoffTime.label')}
                timePickerProps={{ nullable: true }}
                context={PayGroupFormContext}
              />

              <AppTimezonePicker
                showStar
                name="organizationTimezone"
                label={t('payGroups:payGroup.details.payrollCutoffTimezone.label')}
                cellSpan={[12, 6]}
                timezonePickerProps={{ disabled: true }}
                context={PayGroupFormContext}
              />
            </Grid>

            <Grid
              gridColumns={12}
              gridMargins={20}
              align={ALIGNMENT.start}
            >
              <AppSelect
                showStar
                tooltipTitle={t('payGroups:payrollCutoffDayOffset.tooltip.title')}
                tooltipContent={t('payGroups:payrollCutoffDayOffset.tooltip.content')}
                name="payrollCutoffDayOffset"
                label={t('payGroups:payGroup.details.payrollCutoffDayOffset.label')}
                cellSpan={[12, 6]}
                selectProps={{ clearable: false }}
                options={dayOffsets}
                context={PayGroupFormContext}
              />

              <AppSelect
                showStar
                key="pay-day-offset"
                name="paydayOffset"
                label={t('payGroups:payGroup.details.paydayOffset.label')}
                cellSpan={[12, 6]}
                selectProps={{
                  clearable: false,
                }}
                options={dayOffsets}
                context={PayGroupFormContext}
              />
            </Grid>

            <Grid
              gridColumns={12}
              gridMargins={20}
              align={ALIGNMENT.start}
            >
              <AppInput
                name="externalIntegrationCode"
                inputProps={{
                  id: 'externalIntegrationCode',
                  autoComplete: 'off',
                }}
                tooltipTitle={t('payGroups:externalIntegrationCode.tooltip.title')}
                tooltipContent={t('payGroups:externalIntegrationCode.tooltip.content')}
                label={t('payGroups:payGroup.details.externalIntegrationCode.label')}
                cellSpan={[12, 6]}
                context={PayGroupFormContext}
              />
              <AppInput
                name="deductionCode"
                inputProps={{
                  id: 'deductionCode',
                  autoComplete: 'off',
                }}
                tooltipTitle={t('payGroups:deductionCode.tooltip.title')}
                tooltipContent={t('payGroups:deductionCode.tooltip.content')}
                label={t('payGroups:payGroup.details.deductionCode.label')}
                cellSpan={[12, 6]}
                context={PayGroupFormContext}
              />
            </Grid>

            <Grid
              gridColumns={12}
              gridMargins={20}
              align={ALIGNMENT.start}
            >
              <AppTextarea
                name="batchDeliveryEmailAddresses"
                textareaProps={{
                  id: 'batchDeliveryEmailAddresses',
                  autoComplete: 'off',
                }}
                placeholder="john.doe@example.org, doe.john@example.com"
                label={t('payGroups:payGroup.details.batchDeliveryEmailAddresses.label')}
                cellSpan={[12, 12, 6]}
                context={PayGroupFormContext}
                cellProps={{
                  overrides: {
                    Cell: {
                      style: {
                        '@media screen and (min-width: 768px)': {
                          height: '100px',
                          display: 'grid !important',
                          marginBottom: '50px',
                        },
                      },
                    },
                  },
                }}
              />
              <AppCheckbox
                name="automaticPayrollPeriodGenerationEnabled"
                label={t('payGroups:payGroup.details.automaticPayrollPeriodGenerationEnabled.label')}
                cellSpan={[12, 6]}
                context={PayGroupFormContext}
                cellAlign={ALIGNMENT.center}
              />
            </Grid>
          </AppModal>
        </form>
      </PayGroupFormContext.Provider>
    </Layer>
  );
};

export default memo(OrganizationFormPayGroupModal);
