import { useStyletron } from 'baseui';
import moment from 'moment';
import {
  ChangeEvent,
  memo, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  dashAccountTransactionsAreFetchedSelector, dashAccountTransactionsPendingListSelector, dashAccountTransactionsSelector, fetchDashAccountTransactions,
} from 'store/slices/transactions';
import { userAccessUnitsSelector } from 'store/slices/user';
import { Select, Value } from 'baseui/select';
import {
  DashTransactionsValueType, DashTypesItemType, FetchDashAccountTransactionsPropsType, PaycardTrasanctionsType,
} from 'types/MasterAccountTypes';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import { Card } from 'baseui/card';
import { Block } from 'baseui/block';
import {
  Table,
  SIZE,
} from 'baseui/table-semantic';
import { datePickerOverrides } from 'components/Form/AppDatePicker';
import priceFormatter from 'utils/priceFormatter';
import { containerStyles } from 'screens/CommonHelpers';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { AccessUnit } from 'components/Access/Access';
import { DatePicker } from 'baseui/datepicker';
import Loader from 'components/Loader';
import CellFormControl from 'components/CellFormControl';
import { InputCustomHTMLElement, OptionalDateOrDateArrayType } from 'types/CommonTypes';
import { Input } from 'baseui/input';
import { FormControl } from 'baseui/form-control';

const DashAccountTransactions = () => {
  const { t } = useTranslation(['treasury', 'dateFormats', 'errors']);
  const [css] = useStyletron();
  const dispatch = useAppDispatch();
  const mountNodeRef = useRef(null);
  const startDateInputRef = useRef(null);
  const transactions = useAppSelector(dashAccountTransactionsSelector);
  const pending = useAppSelector(dashAccountTransactionsPendingListSelector);
  const areFetched = useAppSelector(dashAccountTransactionsAreFetchedSelector);
  const userRoles = useAppSelector(userAccessUnitsSelector);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [transactionType, setTransactionType] = useState<Value>();
  const [proxy, setProxyNumber] = useState<string>('');
  const [proxyError, setProxyError] = useState<string | null>(null);
  const dateFormat = t('dateFormats:standard');
  const dateFormatTime = t('dateFormats:standard-with-time');
  const datePickerDateFormat = t('dateFormats:date-picker.standard');

  const filteredTransactions = [...transactions]?.sort((a, b) => (moment(b?.transactionDate).unix()) - (moment(a?.transactionDate).unix()));

  const fetchTransactions = () => {
    const transactionTypeEnum = (transactionType && transactionType[0]?.value?.toString()) || '';
    const filterParams: FetchDashAccountTransactionsPropsType = {
      // Subtraction/addition of one day due to defect D83955
      proxyNumber: proxy || '',
      startDate: (startDate && moment(startDate).subtract(1, 'days').format('YYYY-MM-DD')) || '',
      endDate: (endDate && moment(endDate).add(1, 'days').format('YYYY-MM-DD')) || '',
      transactionType: transactionTypeEnum || '',
    };

    dispatch(fetchDashAccountTransactions(filterParams));
  };

  const listOfTypes: DashTypesItemType[] = [
    { label: t('treasury:transactionsType.DEBIT'), value: PaycardTrasanctionsType.DEBIT },
    { label: t('treasury:transactionsType.CREDIT'), value: PaycardTrasanctionsType.CREDIT },
  ];

  const handleStartDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    if (!date) {
      const startDateRef = startDateInputRef.current as any;
      setTimeout(() => {
        startDateRef.focusCalendar();
        startDateRef.close();
      }, 100);
    }
    if (date && !Array.isArray(date)) {
      setStartDate(date as Date);
      if (endDate && moment(date).isAfter(moment(endDate))) {
        setEndDate(undefined);
      }
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };

  const handleEndDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    if (date && !Array.isArray(date)) {
      setEndDate(date as Date);
    } else {
      setEndDate(undefined);
    }
  };

  const handleChangeProxynNumber = (e: ChangeEvent<InputCustomHTMLElement>) => {
    const { value } = e.target;

    if (!/^\d{13}$/.test(value) && value) {
      setProxyError(t('errors:proxyNumberError'));
    } else {
      setProxyError('');
    }

    setProxyNumber(value);
  };

  useEffect(() => {
    !proxyError && fetchTransactions();
  }, [startDate, endDate, transactionType, proxy, proxyError]);

  return (
    <div className={css(containerStyles)}>
      <CommonHeader
        title={`${t('dashMFAHistory')}`}
        backTo={userRoles.includes(AccessUnit.EWAReport) ? '/treasury/section/1' : '/treasury/section/0'}
      />

      <Loader active={pending && !areFetched} />

      <Block
        marginTop="24px"
        marginBottom="16px"
      >
        <Grid
          align={ALIGNMENT.center}
        >
          <Cell span={12}>
            <b>
              {t('common:searchBy')}
            </b>
          </Cell>

          <CellFormControl
            cellSpan={4}
            label={`${t('treasury:transactionType.label')}`}
          >
            <Select
              size={SIZE.compact}
              clearable
              id="select"
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'select-dash-transaction-type',
                    id: 'dash-transaction-type-container',
                  },
                },
                Input: {
                  props: {
                    'data-testid': 'select-dash-transaction-type-input',
                    id: 'dash-transaction-type-input',
                  },
                },
              }}
              placeholder={t('treasury:transactionType.title')}
              type="select"
              options={listOfTypes}
              labelKey="label"
              valueKey="value"
              onChange={({ value }) => setTransactionType(value)}
              value={transactionType as Value}
              maxDropdownHeight="300px"
            />
          </CellFormControl>
          <CellFormControl
            cellSpan={4}
            label={t('treasury:proxyNumber.title')}
          >
            <FormControl
              error={proxyError}
              overrides={{
                ControlContainer: {
                  style: () => ({
                    marginBottom: '0',
                  }),
                  props: {
                    'data-testid': 'FormControl-dash-transactions-proxyNumber',
                    id: 'FormControl-dash-transactions-proxyNumber',
                  },
                },
              }}
            >
              <Input
                error={!!proxyError}
                size={SIZE.compact}
                clearable
                clearOnEscape
                autoComplete="off"
                name="search"
                type="text"
                id="transaction-history-proxy-search"
                onChange={handleChangeProxynNumber}
                value={proxy}
                placeholder={t('treasury:proxyNumber.title')}
              />

            </FormControl>
          </CellFormControl>
          <CellFormControl
            cellSpan={4}
            label={`${t('treasury:transactionStartDate.label')}`}
          >
            <DatePicker
              size={SIZE.compact}
              clearable
              ref={startDateInputRef}
              mountNode={mountNodeRef?.current as any}
              placeholder={t('treasury:transactionStartDate.title')}
              formatString={datePickerDateFormat}
              value={startDate}
              onChange={handleStartDateChange}
              overrides={{
                ...datePickerOverrides,
                Popover: {
                  props: {
                    id: 'transactions-start-date-container',
                  },
                },
              }}
            />
          </CellFormControl>

          <div id="mount-calendar-here" ref={mountNodeRef} />

          <CellFormControl
            cellSpan={4}
            label={`${t('treasury:transactionEndDate.label')}`}
          >
            <DatePicker
              clearable
              placeholder={t('treasury:transactionEndDate.title')}
              formatString={datePickerDateFormat}
              value={endDate}
              onChange={handleEndDateChange}
              minDate={startDate}
              disabled={!startDate}
              overrides={{
                ...datePickerOverrides,
                Popover: {
                  props: {
                    id: 'transactions-end-date-container',
                  },
                },
              }}
            />
          </CellFormControl>
        </Grid>
      </Block>

      {transactions?.length > 0 && areFetched && filteredTransactions?.length > 0
        ? (
          <Block>
            <Grid>
              <Cell span={12}>
                <Table
                  overrides={{
                    TableBodyRow: {
                      style: {
                        ':nth-child(even)': {
                          background: '#F5F5F5',
                        },
                      },
                    },
                    TableBodyCell: {
                      style: {
                        verticalAlign: 'middle',
                      },
                    },
                  }}
                  columns={[
                    t('treasury:dashTable.transactionDate'),
                    t('treasury:dashTable.transactionID'),
                    t('treasury:dashTable.proxyNumber'),
                    t('treasury:dashTable.datePosted'),
                    t('treasury:dashTable.amount'),
                    t('treasury:dashTable.type'),
                    t('treasury:dashTable.description'),
                    t('treasury:dashTable.additionalInfo'),
                  ]}
                  data={filteredTransactions?.map((transaction: DashTransactionsValueType) => {
                    const {
                      proxyNumber,
                      transactionId,
                      transactionDate,
                      datePosted,
                      amount,
                      description,
                      additionalInfo,
                      type,
                    } = transaction;

                    return [
                      moment(transactionDate).format(dateFormatTime),
                      transactionId,
                      proxyNumber,
                      moment(datePosted).format(dateFormat),
                      priceFormatter().format(amount),
                      type,
                      description,
                      additionalInfo,
                    ];
                  })}
                  size={SIZE.compact}
                />
              </Cell>
            </Grid>
          </Block>
        ) : (
          <Block
            marginTop="8px"
            marginBottom="8px"
          >
            <Grid>
              <Cell span={12}>
                <Card>
                  <p>{t('notFound')}</p>
                </Card>
              </Cell>
            </Grid>
          </Block>
        )}
    </div>
  );
};

export default memo(DashAccountTransactions);
