import { PayGroup } from '../store/slices/payGroups';
import { OrganizationIDType } from './OrganizationTypes';

export type FetchPayPeriodType = {
  organizationId: OrganizationIDType;
  pageNumber?: number;
  pageSize?: number;
  from?: string;
  to?: string;
  statuses?: string;
  actionRequired?: boolean;
};

export enum PayPeriodStatusEnum {
  FUTURE = 'FUTURE',
  OPEN = 'OPEN',
  LOCKED = 'LOCKED',
  CLOSED = 'CLOSED',
}

export enum PayPeriodDeductionStatusEnum {
  PARTIALLY_RECOVERED = 'PARTIALLY_RECOVERED',
  FULLY_RECOVERED = 'FULLY_RECOVERED',
}

export enum PayPeriodRequiredActionsEnum {
  CREATE_BATCH = 'CREATE_BATCH',
  INCLUDE_LEDGERS_IN_BATCH = 'INCLUDE_LEDGERS_IN_BATCH',
  SETTLE_LEDGERS_IN_BATCH = 'SETTLE_LEDGERS_IN_BATCH',
}

export type PayPeriodType = {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  payGroup: PayGroup;
  ewaCutoffTime: string;
  tcoCutoffTime: string;
  officialCutoffTime: string;
  payDate?: string;
  ewaStatus?: PayPeriodStatusEnum;
  tcoStatus?: PayPeriodStatusEnum;
  sentToPayroll: boolean;
  deductionStatus?: PayPeriodDeductionStatusEnum;
  ewaRequiredActions: PayPeriodRequiredActionsEnum[];
  tpoRequiredActions: PayPeriodRequiredActionsEnum[];
};

export type PayPeriodStatusFilterType = {
  id: number;
  value: string;
  name: string;
};

export type PayPeriodActionRequiredFilterType = {
  id: number;
  value: boolean;
  name: string;
};

export type PayPeriodStatusFormType = {
  sentToPayroll: PayPeriodType['sentToPayroll'],
  deductionStatus: {
    value: PayPeriodDeductionStatusEnum,
    label: string,
  }[],
}

export type UpdatePayPeriodActionsType = {
  organizationId: OrganizationIDType,
  payPeriodId: PayPeriodType['id'],
  payGroupId: PayGroup['id'],
  payload: {
    sentToPayroll: PayPeriodType['sentToPayroll'],
    deductionStatus?: PayPeriodDeductionStatusEnum,
  },
}

export type FetchUtilizationReportType = {
  organizationId: OrganizationIDType,
  organizationName: string,
}
