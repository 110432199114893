import { BatchIDType } from 'types/BatchTypes';
import { EmploymentIDType } from 'types/EmployeeTypes';
import {
  OrganizationIDType,
} from 'types/OrganizationTypes';
import {
  CreateBatchAllocationsRequestType,
  CreateDrawAllocationsRequestType,
  CreateEmploymentAllocationsRequestType,
  CreateTPOBatchAllocationRequestType,
  RepaymentIDType,
  RepaymentUpdateType,
  SaveRepaymentRequestType,
} from 'types/RepaymentTypes';
import request from './request';

export const fetchAllRepaymentsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  pageNumber: string,
) => {
  const queryParams = new URLSearchParams({
    pageNumber,
  })?.toString();

  return request({
    token,
    url: `organizations/${organizationID}/repayments?${queryParams}`,
  });
};

export const createRepaymentRequest = (
  token: string,
  organizationID: OrganizationIDType,
  data: SaveRepaymentRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `organizations/${organizationID}/repayments`,
});

export const fetchBatchEmploymentDrawAllocationsRequest = (
  token: string,
  batchID: BatchIDType,
  employmentID: EmploymentIDType,
) => request({
  token,
  url: `batches/${batchID}/employments/${employmentID}/draw-allocations`,
});

export const fetchAllocatedBatchEmploymentDrawAllocationsRequest = (
  token: string,
  batchID: BatchIDType,
  repaymentID: RepaymentIDType,
  employmentID: EmploymentIDType,
) => request({
  token,
  url: `/repayments/${repaymentID}/batches/${batchID}/employments/${employmentID}/draw-allocations`,
});

export const fetchAllocatedBatchEmploymentRequest = (
  token: string,
  batchID: BatchIDType,
  repaymentID: RepaymentIDType,
  pageNumber: string,
) => request({
  token,
  url: `/repayments/${repaymentID}/batches/${batchID}/employment-allocations?pageNumber=${pageNumber}`,
});

export const fetchAllocatedTPOBatchEmploymentRequest = (
  token: string,
  batchID: BatchIDType,
) => request({
  token,
  url: `/tco-batches/${batchID}/employment-allocations`,
});

export const fetchAllocatedTPOBatchEmploymentPaymentAllocationsRequest = (
  token: string,
  batchID: BatchIDType,
  employmentID: EmploymentIDType,
) => request({
  token,
  url: `/tco-batches/${batchID}/employments/${employmentID}/payment-allocations`,
});

export const createDrawAllocationsRequest = (
  token: string,
  batchID: BatchIDType,
  repaymentID: RepaymentIDType,
  employmentID: EmploymentIDType,
  data: CreateDrawAllocationsRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `/repayments/${repaymentID}/batches/${batchID}/employments/${employmentID}/draw-allocations/bulk`,
});

export const createEmploymentAllocationsRequest = (
  token: string,
  repaymentID: RepaymentIDType,
  batchID: BatchIDType,
  data: CreateEmploymentAllocationsRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `/repayments/${repaymentID}/batches/${batchID}/employment-allocations/bulk`,
});

export const createBatchAllocationsRequest = (
  token: string,
  repaymentID: RepaymentIDType,
  data: CreateBatchAllocationsRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `/repayments/${repaymentID}/batch-allocations/bulk`,
});

export const updateRepaymentRequest = (
  token: string,
  organizationID: OrganizationIDType,
  id: RepaymentIDType,
  data: RepaymentUpdateType,
) => request({
  token,
  method: 'PATCH',
  payload: data,
  url: `/organizations/${organizationID}/repayments/${id}`,
});

export const fetchRepaymentRequest = (
  token: string,
  organizationID: OrganizationIDType,
  id: RepaymentIDType,
) => request({
  token,
  url: `/organizations/${organizationID}/repayments/${id}`,
});

export const createTPOBatchAllocationRequest = (
  token: string,
  organizationID: OrganizationIDType,
  repaymentID: RepaymentIDType,
  data: CreateTPOBatchAllocationRequestType,
) => request({
  token,
  method: 'PATCH',
  payload: data,
  url: `/organizations/${organizationID}/tco-repayments/${repaymentID}/allocate`,
});
