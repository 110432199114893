import { ReactNode } from 'react';
import { FormControl, FormControlProps, FormControlState } from 'baseui/form-control';
import { colors } from 'theme';
import { Button, KIND, SIZE } from 'baseui/button';
import AppTooltip from './AppTooltip';

export type AppFormControlPropsType = {
  children: ReactNode
  label?: any
  showStar?: boolean
  tooltipTitle?: ReactNode
  tooltipContent?: ReactNode
  error: any
}

const AppFormControl = ({
  children,
  label,
  showStar,
  tooltipTitle,
  tooltipContent,
  error,
  ...rest
}: Partial<AppFormControlPropsType & FormControlProps & FormControlState>) => (
  <FormControl
    error={error}
    label={(
      <>
        {label}
        {showStar && <span style={{ color: colors.primary }}> *</span>}
        {(tooltipTitle || tooltipContent) && (
          <Button
            size={SIZE.compact}
            kind={KIND.tertiary}
            type="button"
            overrides={{
              Root: {
                style: {
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              },
            }}
          >
            <AppTooltip title={tooltipTitle} content={tooltipContent} />
          </Button>
        )}
      </>
    )}
    overrides={{
      Label: {
        style: {
          lineHeight: '20px',
        },
      },
    }}
    {...rest}
  >
    {children}
  </FormControl>
);

export default AppFormControl;
