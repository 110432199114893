import {
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { useStyletron } from 'baseui';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { Block } from 'baseui/block';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import CellFormControl from 'components/CellFormControl';
import {
  OnChangeParams,
  Select,
  SIZE,
  Value,
} from 'baseui/select';
import {
  MODE,
  StatefulButtonGroup,
} from 'baseui/button-group';
import {
  fetchLocationConfigs,
  fetchLocations,
  locationConfigSelector,
  locationsSelector,
} from 'store/slices/locations';
import {
  fetchPayrollPeriods,
  payGroupPayrollPeriodsSelector,
  resetPayGroupPayRollPeriods,
} from 'store/slices/payGroups';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  fetchTPOPayrollFile,
  fetchTippedEmployeesByPayPeriod,
  initiateTPOPayments,
  resetTPOSearchByPayrollPeriod,
  resetTPOPayrollData,
  resetTippedEmployeesSelectedLocation,
  setTPOSearchPayPeriod,
  setTippedEmployeesSelectedLocation,
  tpoPayrollDataSelector,
  tpoSearchPayrollPeriodSelector,
  tippedEmployeesSelectedLocationSelector,
  tpoPayrollDataPendingSelector,
  selectedTippedEmployeesSelector,
  resetSelectedTippedEmployees,
  setIsSingleTipPayoutOptionChosen,
  isSingleTipPayoutOptionChosenSelector,
  setTPOSearchBusinessDate,
  tpoSearchBusinessDateSelector,
  tippedEmployeesByPayPeriodPendingListSelector,
  tippedEmployeesByPayPeriodListSelector,
  resetTippedEmployeesByPayPeriod,
  resetTippedEmployeesByPayPeriodPendingList,
  tippedEmployeesByBusinessDatePendingListSelector,
  tpoSearchByPayrollPeriodEmployeeStatusSelector,
  resetTPOSearchByBusinessDate,
  tpoSearchByPayrollPeriodPaymentStatusSelector,
  tpoSearchByPayrollPeriodNameSelector,
  resetTPOSearchByPayrollPeriodChanged,
  fetchTPODailyFile,
  tippedEmployeesByBusinessDateListSelector,
  tpoDailyDataSelector,
  tpoDailyDataPendingSelector,
  resetTPODailyDataFile,
  resetAndSetTPOSearchByPayrollPeriod,
  resetAndSetTPOSearchByBusinessDate,
} from 'store/slices/tpo';
import { HeadingMedium } from 'baseui/typography';
import moment from 'moment-timezone';
import {
  Button,
  KIND,
} from 'baseui/button';
import {
  FetchTippedEmployeesByPayPeriodParamsType,
} from 'types/TPOTypes';
import {
  AccessCheckType,
  AccessUnit,
} from 'components/Access/Access';
import hasAccess from 'utils/hasAccess';
import {
  prevPageSelector,
  setPrevPage,
} from 'store/slices/application';
import {
  fetchOrganizationConfigs,
  organizationConfigSelector,
} from 'store/slices/organizations';
import { ConfigType } from 'types/OrganizationTypes';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import {
  NotificationType,
  setNotification,
} from 'store/slices/notification';
import DownloadFile from 'utils/downloadFile';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faLocationDot,
  faRotate,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { colors } from 'theme';
import {
  PLACEMENT,
  Popover,
} from 'baseui/popover';
import { ChevronDown } from 'baseui/icon';
import { DatePicker } from 'baseui/datepicker';
import { datePickerOverrides } from 'components/Form/AppDatePicker';
import { OptionalDateOrDateArrayType } from 'types/CommonTypes';
import TipManagementUploadTipsFileModal from './TipManagementUploadTipsFileModal';
import {
  contentLocationContainerStyles,
  containerStyles,
  popoverExportOverrides,
} from './TipManagementHelper';
import TipManagementPayPeriodSection from './TipManagementPayPeriodSection';
import TipManagementBusinessDateSection from './TipManagementBusinessDateSection';

const TipManagement = () => {
  const { t } = useTranslation(['common', 'tipsManagement', 'dateFormats']);
  const dispatch = useAppDispatch();
  const [css, theme] = useStyletron();
  const dateFormat = t('dateFormats:standard');
  const dateFormatReverse = t('dateFormats:standard-reverse');

  const loggedOrganizationID = useAppSelector(loggedOrganizationSelector)?.id;
  const locations = useAppSelector(locationsSelector);
  const payrollPeriods = useAppSelector(payGroupPayrollPeriodsSelector);

  const payrollPeriod = useAppSelector(tpoSearchPayrollPeriodSelector);
  const employeeStatus = useAppSelector(tpoSearchByPayrollPeriodEmployeeStatusSelector);
  const businessDate = useAppSelector(tpoSearchBusinessDateSelector);
  const search = useAppSelector(tpoSearchByPayrollPeriodNameSelector);
  const paymentStatus = useAppSelector(tpoSearchByPayrollPeriodPaymentStatusSelector);
  const isSingleTPOChecked = useAppSelector(isSingleTipPayoutOptionChosenSelector);

  const location = useAppSelector(tippedEmployeesSelectedLocationSelector);
  const [searchPayPeriodChanged, setSearchPayPeriodHasChanged] = useState<boolean>(false);

  const [locationChanged, setLocationChanged] = useState(false);
  const [exportTipReportClicked, setExportTipReportClicked] = useState<boolean>(false);
  const [isPayPeriodChecked, setIsPayPeriodChecked] = useState<boolean>(true);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isExportDailyAllBtnClicked, setIsExportDailyAllBtnClicked] = useState<boolean>(false);
  const [isExportDailyPartialBtnClicked, setIsExportDailyPartialBtnClicked] = useState<boolean>(false);

  const [runTPOPaymentsButtonEnabled, setRunTPOPaymentsButtonEnabled] = useState(true);

  const selectedTippedEmployees = useAppSelector(selectedTippedEmployeesSelector);

  const pendingList = useAppSelector(tippedEmployeesByPayPeriodPendingListSelector);
  const pendingListByBusinessDate = useAppSelector(tippedEmployeesByBusinessDatePendingListSelector);
  const prevPage = useAppSelector(prevPageSelector);

  const tippedEmployees = useAppSelector(tippedEmployeesByPayPeriodListSelector);
  const tippedEmployeesPerDay = useAppSelector(tippedEmployeesByBusinessDateListSelector);
  const organizationConfig = useAppSelector(organizationConfigSelector);
  const locationConfig = useAppSelector(locationConfigSelector);

  const tpoFileData = useAppSelector(tpoPayrollDataSelector);
  const tpoFilePending = useAppSelector(tpoPayrollDataPendingSelector);

  const tpoFilePerDayData = useAppSelector(tpoDailyDataSelector);
  const tpoFilePerDayPending = useAppSelector(tpoDailyDataPendingSelector);

  const modals = useAppSelector(modalsSelector);
  const isConfirmModalOpen = !!modals?.find((item) => item.name === ModalNames.CONFIRM_MODAL)?.isOpen;

  const organizationTPODailyPayoutCondition = organizationConfig
    ?.find((item) => item.configKeyName === ConfigType.TPO_DAILY_PAYOUT_SCHEDULE)?.configValue === 'API'
    || organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_DAILY_PAYOUT_SCHEDULE)?.configValue === 'API_OR_SCHEDULED';
  const organizationTPOEnabledOrgAndLocation = organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_ENABLED)?.configValue === 'true'
    && locationConfig?.find((i) => i.configKeyName === ConfigType.TPO_ENABLED)?.configValue === 'true';

  const orgTimezone = organizationConfig?.find((item) => item.configKeyName === ConfigType.CORPORATE_TIMEZONE)?.configValue;
  const locationTimezone = location[0]?.timezone;

  const currentTimeIsBeforeOrgTPOCutoffDate = orgTimezone
    && moment(moment.tz(new Date(), orgTimezone).format('YYYY-MM-DDTHH:mm:ss')).isBefore(payrollPeriod[0]?.tcoCutoffTime);
  const currentTimeIsAfterLocationStartDate = moment(moment.tz(new Date(), locationTimezone).format('YYYY-MM-DDTHH:mm:ss'))
    .isAfter(payrollPeriod[0]?.startDate);
  const currentTimeIsBeforeLocationStartDate = moment(moment.tz(new Date(), locationTimezone).format('YYYY-MM-DDTHH:mm:ss'))
    .isBefore(payrollPeriod[0]?.startDate);

  // eslint-disable-next-line max-len
  const organizationTPOInclude3rdPartyTipsOrgAndLocation = organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_INCLUDE_3RD_PARTY_TIPS)?.configValue === 'true'
    && locationConfig?.find((i) => i.configKeyName === ConfigType.TPO_INCLUDE_3RD_PARTY_TIPS)?.configValue === 'true';
  // eslint-disable-next-line max-len
  const organizationEWAInclude3rdPartyTipsOrgAndLocation = organizationConfig?.find((item) => item.configKeyName === ConfigType.INCLUDE_3RD_PARTY_TIPS)?.configValue === 'true'
    && locationConfig?.find((i) => i.configKeyName === ConfigType.INCLUDE_3RD_PARTY_TIPS)?.configValue === 'true';

  const runTipPaymentsCondition = payrollPeriod[0]?.tcoStatus === 'OPEN'
    || (payrollPeriod[0]?.tcoStatus === 'FUTURE'
      && currentTimeIsAfterLocationStartDate
      && currentTimeIsBeforeOrgTPOCutoffDate);

  const datePickerOverridesExtended = (id: string) => ({
    ...datePickerOverrides,
    Input: {
      ...datePickerOverrides.Input,
      props: {
        onKeyDown: (event: any) => event.preventDefault(),
        overrides: {
          ...datePickerOverrides.Input.props.overrides,
        },
        id,
      },
    },
  });

  const setIsConfirmModalOpen = (
    isOpen: boolean,
  ) => {
    isConfirmModalOpen !== isOpen && dispatch(setModal({
      name: ModalNames.CONFIRM_MODAL,
      isOpen,
    }));
  };

  const openImportTipsFileModal = () => {
    dispatch(setModal({
      name: ModalNames.UPLOAD_TIPS_FILE_MODAL,
      isOpen: true,
    }));
  };

  const handleLocationChange = ({ value }: OnChangeParams): void => {
    dispatch(resetTippedEmployeesByPayPeriod());
    dispatch(resetSelectedTippedEmployees());
    dispatch(resetTPOSearchByPayrollPeriod());
    dispatch(resetTPOSearchByPayrollPeriodChanged());
    dispatch(resetTPOSearchByBusinessDate());
    setLocationChanged(true);
    dispatch(setTippedEmployeesSelectedLocation(value));
    if (value[0] && value[0].payGroup?.id) {
      setRunTPOPaymentsButtonEnabled(true);
      dispatch(fetchPayrollPeriods({ organizationID: loggedOrganizationID, payGroupID: value[0]?.payGroup.id }));
      dispatch(fetchLocationConfigs({ organizationID: loggedOrganizationID, locationID: value[0].id?.toString() }));
    } else {
      dispatch(resetPayGroupPayRollPeriods());
      dispatch(setTPOSearchPayPeriod([]));
      dispatch(resetTippedEmployeesByPayPeriodPendingList());
    }
  };

  const handlePayPeriodChange = ({ value }: OnChangeParams): void => {
    setRunTPOPaymentsButtonEnabled(true);
    setSearchPayPeriodHasChanged(true);
    dispatch(setTPOSearchPayPeriod(value));
    dispatch(resetTippedEmployeesByPayPeriod());
    dispatch(resetSelectedTippedEmployees());
  };

  const handleBusinessDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    setRunTPOPaymentsButtonEnabled(true);
    dispatch(setTPOSearchBusinessDate(date));
  };

  const runTPOPaymentsHandler = () => {
    dispatch(initiateTPOPayments({
      organizationID: loggedOrganizationID,
      locationID: location?.[0]?.id?.toString(),
      payrollPeriodID: payrollPeriod?.[0].id?.toString(),
      employmentIds: selectedTippedEmployees.map((employee) => employee.id).toString(),
    })).then(() => {
      dispatch(resetSelectedTippedEmployees());
    });
    setRunTPOPaymentsButtonEnabled(false);
    setIsConfirmModalOpen(false);
  };

  const handleOnCloseRunTPOPayments = () => {
    setIsConfirmModalOpen(false);
    dispatch(setNotification({
      isOpen: true,
      type: NotificationType.WARNING,
      title: t('tipsManagement:runTipPayments.confirmationModal.close.message'),
      autoHideDuration: 3000,
    }));
    dispatch(resetSelectedTippedEmployees());
  };

  const handleGenerateTPOPayrollFileBtnClick = (forLocation: boolean) => {
    if (forLocation) {
      setExportTipReportClicked(false);
      dispatch(fetchTPOPayrollFile({ payrollPeriodID: payrollPeriod[0]?.id.toString(), locationID: location?.[0]?.id?.toString() }));
    } else {
      setExportTipReportClicked(true);
      dispatch(fetchTPOPayrollFile({ payrollPeriodID: payrollPeriod[0]?.id.toString() }));
    }
  };

  const handleGenerateTPODailyFileBtnClick = (onlyThirdParty?: boolean) => {
    if (onlyThirdParty) {
      setIsExportDailyAllBtnClicked(false);
      setIsExportDailyPartialBtnClicked(true);
    } else {
      setIsExportDailyAllBtnClicked(true);
      setIsExportDailyPartialBtnClicked(false);
    }
    if (location[0].id) {
      dispatch(fetchTPODailyFile({
        organizationID: loggedOrganizationID || '',
        locationID: location[0].id,
        locationName: location[0].name,
        businessDate: moment(businessDate?.toString()).format(dateFormatReverse),
        onlyThirdParty,
      })).then(() => {
        setIsExportDailyAllBtnClicked(false);
        setIsExportDailyPartialBtnClicked(false);
      });
    } else {
      setIsExportDailyAllBtnClicked(false);
      setIsExportDailyPartialBtnClicked(false);
      dispatch(setNotification({
        type: NotificationType.ERROR,
        isOpen: true,
        title: t('errors:error'),
        text: t('tipsManagement:fetchDailyFile.noLocationIdError'),
        autoHideDuration: 3000,
      }));
    }
  };

  useEffect(() => {
    if (tpoFileData?.csv) {
      DownloadFile(
        `${tpoFileData?.id}.csv`,
        `data:text/csv;charset=UTF-8,${encodeURIComponent(tpoFileData?.csv)}`,
      );
      dispatch(resetTPOPayrollData());
    }
  }, [tpoFileData?.id]);

  useEffect(() => {
    if (tpoFilePerDayData && tpoFilePerDayData?.csv) {
      DownloadFile(
        `${tpoFilePerDayData?.id}.csv`,
        `data:text/csv;charset=UTF-8,${encodeURIComponent(tpoFilePerDayData?.csv)}`,
      );
      dispatch(resetTPODailyDataFile());
    }
  }, [tpoFilePerDayData]);

  useEffect(() => {
    if ((location && location[0]?.id)
      && (payrollPeriod && Object.keys(payrollPeriod).length !== 0)
      && (searchPayPeriodChanged || locationChanged)) {
      const filter = new Map([
        ['organizationID', loggedOrganizationID],
        ['locationID', location?.[0]?.id?.toString()],
        ['payrollPeriodID', payrollPeriod?.[0].id?.toString()],
        ['pageNumber', '1'],
        ['employmentStatuses', (employeeStatus && employeeStatus?.[0]?.name) || ''],
        ['statuses', (paymentStatus && paymentStatus?.[0]?.value) || ''],
        ['name', search || ''],
      ]);
      dispatch(fetchTippedEmployeesByPayPeriod(Object.fromEntries(filter) as FetchTippedEmployeesByPayPeriodParamsType));
    }
  }, [
    payrollPeriod,
    searchPayPeriodChanged,
    locationChanged,
  ]);

  useEffect(() => {
    const openPayrollPeriod = payrollPeriods.filter((i) => i.tcoStatus === 'OPEN');
    if ((location && location?.[0]?.id) && isPayPeriodChecked) {
      if (openPayrollPeriod.length !== 0) {
        dispatch(setTPOSearchPayPeriod(openPayrollPeriod));
        setSearchPayPeriodHasChanged(true);
      } else if (payrollPeriods && Object.keys(payrollPeriods).length !== 0) {
        dispatch(setTPOSearchPayPeriod([payrollPeriods[0]]));
        setSearchPayPeriodHasChanged(true);
      } else {
        dispatch(resetPayGroupPayRollPeriods());
        dispatch(resetTippedEmployeesByPayPeriod());
        dispatch(setTPOSearchPayPeriod([]));
        setSearchPayPeriodHasChanged(true);
      }
    }
  }, [payrollPeriods,
    isPayPeriodChecked]);

  useEffect(() => {
    dispatch(resetSelectedTippedEmployees());
  }, [isSingleTPOChecked]);

  useEffect(() => {
    if (!organizationTPOEnabledOrgAndLocation
      || !organizationTPODailyPayoutCondition
      || !runTipPaymentsCondition) {
      dispatch(setIsSingleTipPayoutOptionChosen(false));
    }
  }, [runTipPaymentsCondition, organizationTPODailyPayoutCondition, organizationTPOEnabledOrgAndLocation]);

  useEffect(() => {
    dispatch(resetTippedEmployeesByPayPeriodPendingList());
    dispatch(resetTPOSearchByPayrollPeriodChanged());
    dispatch(fetchLocations({
      organizationID: loggedOrganizationID,
    }));
    dispatch(fetchOrganizationConfigs({ organizationID: loggedOrganizationID }));
    setRunTPOPaymentsButtonEnabled(true);
    if (!prevPage.startsWith('/tips')) {
      dispatch(resetTippedEmployeesSelectedLocation());
      dispatch(resetTPOSearchByPayrollPeriod());
      dispatch(resetTPOSearchByBusinessDate());
    }
    dispatch(setPrevPage(''));
  }, []);

  const handleOnRefresh = () => {
    const openPayrollPeriod = payrollPeriods.filter((i) => i.tcoStatus === 'OPEN');
    if (isPayPeriodChecked && openPayrollPeriod.length !== 0) {
      dispatch(resetAndSetTPOSearchByPayrollPeriod(openPayrollPeriod));
    } else {
      dispatch(resetAndSetTPOSearchByBusinessDate());
    }
  };

  return (
    <div className={css(containerStyles)}>
      <CommonHeader title={t('tipsManagement:tipsManagementHeader')}>
        <Block
          alignItems="center"
          alignContent="end"
          justifyItems="end"
          display="inline-flex"
          justifyContent="flex-end"
          width="50%"
          minWidth="160px"
          height="72px"
        >
          {hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager, AccessUnit.EWAClientManager, AccessUnit.EWAClientLocationManager])
            && isPayPeriodChecked
            && (
              <div>
                <Button onClick={() => setIsPopoverOpen(!isPopoverOpen)} kind={KIND.secondary} endEnhancer={<ChevronDown />}>
                  {t('common:export')}
                </Button>
                <Popover
                  isOpen={isPopoverOpen}
                  onClickOutside={() => setIsPopoverOpen(false)}
                  placement={PLACEMENT.bottomRight}
                  content={() => (
                    <div className={css(popoverExportOverrides)}>
                      <Button
                        onClick={() => { handleGenerateTPOPayrollFileBtnClick(false); setIsPopoverOpen(false); }}
                        disabled={
                          (payrollPeriod[0]?.tcoStatus === 'FUTURE' && currentTimeIsBeforeLocationStartDate)
                          || (tippedEmployees && tippedEmployees.length === 0)
                          || (tpoFilePending && exportTipReportClicked === true)
                          || (location && location.length === 0)
                          || !hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager, AccessUnit.EWAClientManager])
                        }
                        isLoading={tpoFilePending && exportTipReportClicked === true}
                        overrides={{
                          Root: {
                            style: {
                              color: '#545454',
                            },
                            props: {
                              'data-testid': 'TipManagement-exportPayGroupTPOs-btn',
                              id: 'TipManagement-exportPayGroupTPOs-btn',
                            },
                          },
                        }}
                        kind={KIND.tertiary}
                        size={SIZE.compact}
                      >
                        {t('tipsManagement:exportPayGroupTPO.button')}
                      </Button>
                      <Button
                        onClick={() => { handleGenerateTPOPayrollFileBtnClick(true); setIsPopoverOpen(false); }}
                        disabled={
                          (payrollPeriod[0]?.tcoStatus === 'FUTURE' && currentTimeIsBeforeLocationStartDate)
                          || (tippedEmployees && tippedEmployees.length === 0)
                          || (tpoFilePending && exportTipReportClicked === false)
                          || (location && location.length === 0)
                          || !hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager, AccessUnit.EWAClientManager, AccessUnit.EWAClientLocationManager])
                        }
                        isLoading={tpoFilePending && exportTipReportClicked === false}
                        kind={KIND.tertiary}
                        size={SIZE.compact}
                        overrides={{
                          Root: {
                            style: {
                              color: '#545454',
                            },
                            props: {
                              'data-testid': 'TipManagement-exportLocationTPOs-btn',
                              id: 'TipManagement-exportLocationTPOs-btn',
                            },
                          },
                        }}
                      >
                        {t('tipsManagement:exportLocationTPO.button')}
                      </Button>
                    </div>
                  )}
                >
                  <div />
                </Popover>
              </div>
            )}
          {hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager, AccessUnit.EWAClientManager, AccessUnit.EWAClientLocationManager])
            && !isPayPeriodChecked
            && (
              <div>
                <Button
                  onClick={() => { handleGenerateTPODailyFileBtnClick(); }}
                  kind={KIND.secondary}
                  disabled={
                    !businessDate
                    || businessDate > new Date()
                    || (tippedEmployeesPerDay && tippedEmployeesPerDay.length === 0)
                    || (location && location.length === 0)
                    || !hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager, AccessUnit.EWAClientManager, AccessUnit.EWAClientLocationManager])
                  }
                  isLoading={isExportDailyAllBtnClicked && tpoFilePerDayPending}
                  overrides={{
                    Root: {
                      props: {
                        'data-testid': 'TipManagement-exportBusinessDayTPOData-btn',
                        id: 'TipManagement-exportBusinessDayTPOData-btn',
                      },
                    },
                    LoadingSpinner: {
                      style: {
                        borderRightColor: colors.primary,
                        borderTopColor: colors.primary,
                        borderLeftColor: colors.primary,
                      },
                    },
                  }}
                >
                  {t('tipsManagement:dayView.exportTipsReport.button')}
                </Button>
              </div>
            )}
        </Block>
      </CommonHeader>
      <Block marginTop="32px" marginBottom="14px">
        <Grid
          gridColumns={12}
        >
          <div className={css(contentLocationContainerStyles)}>
            <Grid gridColumns={12} gridMargins={20}>
              <CellFormControl
                cellSpan={[12, 4, 2.5]}
                label={t('tipsManagement:location.label')}
              >
                <Select
                  disabled={(isPayPeriodChecked && pendingList) || (!isPayPeriodChecked && pendingListByBusinessDate)}
                  size={SIZE.compact}
                  id="tpo-location-select"
                  clearable
                  placeholder={t('common:select')}
                  type="select"
                  options={locations?.map(({
                    name, id, payGroup, timezone, externalLocationId, tipsProviderId,
                  }) => ({
                    name, id, payGroup, timezone, externalLocationId, tipsProviderId,
                  }))}
                  labelKey="name"
                  valueKey="id"
                  onChange={handleLocationChange}
                  value={location as Value}
                  maxDropdownHeight="300px"
                  overrides={{
                    ControlContainer: {
                      props: {
                        id: 'tpo-management-location-select',
                        'data-testid': 'tips-management-location-selector',
                      },
                    },
                  }}
                />
              </CellFormControl>
              <CellFormControl
                cellSpan={[12, 3, 1.5]}
                cellAlign={ALIGNMENT.end}
              >
                <StatefulButtonGroup
                  size={SIZE.compact}
                  disabled={!location || !location?.length || (isPayPeriodChecked && pendingList) || (!isPayPeriodChecked && pendingListByBusinessDate)}
                  mode={MODE.radio}
                >
                  <Button
                    onClick={() => {
                      setIsPayPeriodChecked(true);
                      dispatch(resetTPOSearchByBusinessDate());
                    }}
                    isSelected={isPayPeriodChecked}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'TipManagement-location-payPeriod-view-btn',
                          name: 'TipManagement-location-payPeriod-view-btn',
                        },
                      },
                    }}
                  >
                    {t('common:payPeriod')}
                  </Button>
                  <Button
                    onClick={() => {
                      setIsPayPeriodChecked(false);
                      dispatch(resetTPOSearchByPayrollPeriod());
                    }}
                    isSelected={!isPayPeriodChecked}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'TipManagement-location-day-view-btn',
                          name: 'TipManagement-location-day-view-btn',
                        },
                      },
                    }}
                  >
                    {t('common:day.label')}
                  </Button>
                </StatefulButtonGroup>
              </CellFormControl>

              {isPayPeriodChecked && (
                <CellFormControl
                  cellSpan={[12, 3, 3]}
                  label={t('common:payPeriod')}
                >
                  <Select
                    disabled={!location || !location?.length || pendingList}
                    size={SIZE.compact}
                    id="pay-period-select"
                    clearable={false}
                    placeholder={t('common:select')}
                    type="select"
                    options={payrollPeriods?.map(({
                      startDate, endDate, id, status, tcoCutoffTime, tcoStatus,
                    }) => ({
                      name: `${moment(startDate).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`,
                      startDate,
                      endDate,
                      id,
                      status,
                      tcoCutoffTime,
                      tcoStatus,
                    }))}
                    labelKey="name"
                    valueKey="id"
                    onChange={handlePayPeriodChange}
                    value={payrollPeriod}
                    maxDropdownHeight="300px"
                    overrides={{
                      ControlContainer: {
                        props: {
                          id: 'tips-management-pay-period-select',
                          'data-testid': 'tips-management-pay-period-selector',
                        },
                      },
                    }}
                  />
                </CellFormControl>
              )}
              {!isPayPeriodChecked && (
                <CellFormControl
                  cellSpan={[12, 3, 2.5]}
                  label={t('tipsManagement:businessDate.label')}
                >
                  <DatePicker
                    size={SIZE.compact}
                    formatDisplayValue={(date: any) => new Intl.DateTimeFormat('en-US', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }).format(date)}
                    disabled={!location || !location?.length || pendingListByBusinessDate}
                    formatString="yyyy/MM/dd"
                    value={businessDate}
                    onChange={handleBusinessDateChange}
                    overrides={datePickerOverridesExtended('tpo-day-view-businessDate-dateRange')}
                  />
                </CellFormControl>
              )}
              <Cell
                span={[2, 2, 0.75]}
                align={ALIGNMENT.center}
                overrides={{
                  Cell: {
                    style: {
                      marginTop: '1.25rem',
                    },
                  },
                }}
              >
                <Button
                  disabled={!location || !location?.length || (isPayPeriodChecked && pendingList) || (!isPayPeriodChecked && pendingListByBusinessDate)}
                  onClick={handleOnRefresh}
                  size={SIZE.compact}
                >
                  <FontAwesomeIcon
                    className={css({ cursor: 'pointer', justifyContent: 'center' })}
                    icon={faRotate}
                  />
                </Button>
              </Cell>
              {(hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager, AccessUnit.TipsClientManager, AccessUnit.EWAClientManager])
                && (organizationTPOInclude3rdPartyTipsOrgAndLocation || organizationEWAInclude3rdPartyTipsOrgAndLocation) && !isPayPeriodChecked)
                && (
                  <CellFormControl
                    cellSpan={[12, 4, 3]}
                    cellAlign={ALIGNMENT.end}
                    formControlProps={{
                      overrides: {
                        ControlContainer: {
                          style: {
                            '@media screen and (min-width: 1136px)': {
                              display: 'inline-flex',
                              justifyItems: 'end',
                              justifyContent: 'flex-end',
                            },
                          },
                        },
                      },
                    }}
                  >
                    <Button
                      kind={KIND.tertiary}
                      size={SIZE.compact}
                      onClick={() => { handleGenerateTPODailyFileBtnClick(true); }}
                      startEnhancer={(
                        <FontAwesomeIcon
                          icon={faDownload}
                        />
                      )}
                      isLoading={tpoFilePerDayPending && isExportDailyPartialBtnClicked}
                      disabled={(!location
                        || !location?.length)
                        || (isPayPeriodChecked && (!tippedEmployees || !tippedEmployees?.length))
                        || (!isPayPeriodChecked && (!tippedEmployeesPerDay || !tippedEmployeesPerDay?.length))
                        || (!isPayPeriodChecked && (businessDate > new Date()))}
                      overrides={{
                        Root: {
                          style: {
                            height: '36px',
                          },
                          props: {
                            'data-testid': 'TipManagement-download-tpo-payroll-template-button',
                            id: 'TipManagement-download-tpo-payroll-template-button',
                          },
                        },
                        LoadingSpinner: {
                          style: {
                            borderRightColor: colors.primary,
                            borderTopColor: colors.primary,
                            borderLeftColor: colors.primary,
                          },
                        },
                      }}
                    >
                      {t('tipsManagement:downloadTemplate.button')}
                    </Button>
                  </CellFormControl>
                )}
              {(hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager, AccessUnit.TipsClientManager, AccessUnit.EWAClientManager])
                && (organizationTPOInclude3rdPartyTipsOrgAndLocation || organizationEWAInclude3rdPartyTipsOrgAndLocation) && !isPayPeriodChecked)
                && (
                  <CellFormControl
                    cellSpan={[12, 3, 1.5]}
                    cellAlign={ALIGNMENT.end}
                    formControlProps={{
                      overrides: {
                        ControlContainer: {
                          style: {
                            '@media screen and (min-width: 1136px)': {
                              display: 'inline-flex',
                              justifyItems: 'end',
                              justifyContent: 'flex-end',
                            },
                          },
                        },
                      },
                    }}
                  >
                    <Button
                      kind={KIND.secondary}
                      size={SIZE.compact}
                      onClick={openImportTipsFileModal}
                      startEnhancer={(
                        <FontAwesomeIcon
                          icon={faUpload}
                        />
                      )}
                      disabled={(!location
                        || !location?.length)
                        || (isPayPeriodChecked && (!tippedEmployees || !tippedEmployees?.length))
                        || (!isPayPeriodChecked && (!tippedEmployeesPerDay || !tippedEmployeesPerDay?.length))
                        || (!isPayPeriodChecked && (businessDate > new Date()))}
                      overrides={{
                        Root: {
                          style: {
                            height: '36px',
                          },
                          props: {
                            'data-testid': 'TipManagement-upload-tpo-payroll-file-button',
                            id: 'TipManagement-import-tpo-payroll-file-button',
                          },
                        },
                      }}
                    >
                      {t('common:uploadFile')}
                    </Button>
                  </CellFormControl>
                )}
            </Grid>
          </div>

        </Grid>
      </Block>
      {(!location || !location?.length) && (
        <Block
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="500px"
        >
          <FontAwesomeIcon
            icon={faLocationDot}
            size="7x"
            className={css({ color: colors.secondary, marginTop: 'auto' })}
          />
          <HeadingMedium
            color={theme.colors.contentInverseSecondary}
            marginBottom="auto"
            overrides={{
              Block: {
                style: {
                  maxWidth: 'unset',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  textAlign: 'center',
                },
              },
            }}
          >
            {t('tipsManagement:selectLocationMessage')}
          </HeadingMedium>
        </Block>
      )}

      {location && location.length && !isPayPeriodChecked ? (
        <TipManagementBusinessDateSection />
      ) : null}
      {location && location.length && isPayPeriodChecked ? (
        <TipManagementPayPeriodSection isRunTPOPaymentsButtonEnabled={runTPOPaymentsButtonEnabled} />
      ) : null}

      <ConfirmModal
        onAction={runTPOPaymentsHandler}
        title={t('tipsManagement:runTipPayments.confirmationModal.title', {
          selectedEmployeesCount: selectedTippedEmployees.length > 0
            ? selectedTippedEmployees.length : 'ALL',
        })}
        actionBtnText={t('common:confirm.ok')}
        onClose={handleOnCloseRunTPOPayments}
      />
      {checkIsModalOpen(modals, ModalNames.UPLOAD_TIPS_FILE_MODAL) && <TipManagementUploadTipsFileModal />}
    </div>
  );
};

export default memo(TipManagement);
