import moment from 'moment';
import {
  useState,
  useEffect,
  memo,
} from 'react';
import { useStyletron } from 'styletron-react';
import { useTranslation } from 'react-i18next';
import { Grid, Cell } from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import {
  OnChangeParams,
  Select,
  SIZE,
} from 'baseui/select';
import { Card } from 'baseui/card';
import {
  loggedOrganizationSelector,
} from 'store/slices/loggedOrganization';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  fetchRepayments,
  updateRepayment,
  repaymentsSelector,
  repaymentsPendingListSelector,
  repaymentsPageNumberSelector,
  repaymentsNumPagesSelector,
  repaymentsTotalSizeSelector,
  repaymentsSearchStatusSelector,
  repaymentsSearchSortBySelector,
  setSearchStatus,
  setSearchSortBy,
  resetRepaymentsSearch,
  repaymentsSourceSelector,
  setRepaymentSource,
} from 'store/slices/repayments';
import {
  blockOverrides, containerStyles,
} from 'screens/Batches/BatchesHelpers';
import CellFormControl from 'components/CellFormControl';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import RepaymentFormModal from 'screens/Batches/BatchAdministration/BatchRepaymentSection/RepaymentForm/RepaymentFormModal';
import Loader from 'components/Loader';
import {
  PaymentStatusType,
  RepaymentIDType,
  RepaymentUpdateType,
  KeyType,
  RepaymentType,
} from 'types/RepaymentTypes';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { Pagination } from 'baseui/pagination';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import { prevPageSelector, setPrevPage } from 'store/slices/application';
import { batchAllocationsCreatedSelector, resetBatchAllocationsCreated } from 'store/slices/paymentAllocation';
import RepaymentListItem from './RepaymentListItem/RepaymentListItem';
import RepaymentEditModal from './RepaymentListItem/RepaymentEditModal';
import TPORepaymentAllocationModal from './TPORepaymentAllocation/TPORepaymentAllocationModal';

const RepaymentRepaymentSection = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'employees', 'repayments']);
  const [css] = useStyletron();
  const organization = useAppSelector(loggedOrganizationSelector);
  const repayments = useAppSelector(repaymentsSelector);
  const pendingList = useAppSelector(repaymentsPendingListSelector);
  const modals = useAppSelector(modalsSelector);
  const pageNumber = useAppSelector(repaymentsPageNumberSelector);
  const totalSize = useAppSelector(repaymentsTotalSizeSelector);
  const numPages = useAppSelector(repaymentsNumPagesSelector);
  const { id: organizationID } = organization || {};
  const status = useAppSelector(repaymentsSearchStatusSelector);
  const sortBy = useAppSelector(repaymentsSearchSortBySelector);
  const [selectedRepayment, setSelectedRepayment] = useState<RepaymentType | undefined>(undefined);
  const prevPage = useAppSelector(prevPageSelector);
  const batchAllocationsCreated = useAppSelector(batchAllocationsCreatedSelector);
  const repaymentSource = useAppSelector(repaymentsSourceSelector);

  const sortByOptions: { value: KeyType, label: string, disabled?: boolean }[] = [
    { value: KeyType.dateReceived, label: t('repayments:sortByOptions.dateReceived') },
    { value: KeyType.amount, label: t('repayments:sortByOptions.amount') },
    { value: KeyType.balance, label: t('repayments:sortByOptions.balance') },
    { value: KeyType.allocatedAmount, label: t('repayments:sortByOptions.allocated') },
    { value: KeyType.transactionId, label: t('repayments:sortByOptions.transactionId') },
  ];

  const statuses = [
    { value: PaymentStatusType.CREATED, label: t('repayments:paymentAllocationOptions.CREATED') },
    { value: PaymentStatusType.RECEIVED, label: t('repayments:paymentAllocationOptions.RECEIVED') },
    { value: PaymentStatusType.ALLOCATED, label: t('repayments:paymentAllocationOptions.ALLOCATED') },
    { value: PaymentStatusType.DISTRIBUTED, label: t('repayments:paymentAllocationOptions.DISTRIBUTED') },
    { value: PaymentStatusType.OPEN, label: t('repayments:paymentAllocationOptions.OPEN') },
    { value: PaymentStatusType.PARTIALLY_ALLOCATED, label: t('repayments:paymentAllocationOptions.PARTIALLY_ALLOCATED') },
    { value: PaymentStatusType.TO_BE_DISTRIBUTED, label: t('repayments:paymentAllocationOptions.TO_BE_DISTRIBUTED') },
  ];

  const filteredRepayments = repayments?.filter((item) => {
    if (status && status?.length > 0) {
      return item?.status?.toString() === status[0]?.value;
    }

    return true;
  });

  const sortedRepayments = () => {
    if (sortBy && sortBy?.length > 0) {
      const key: KeyType = sortBy && sortBy[0]?.value;

      return [...filteredRepayments].sort((a, b) => {
        switch (key) {
          case KeyType.dateReceived:
            return moment(b[key] as string).valueOf() - moment(a[key] as string).valueOf();

          case KeyType.transactionId:
            return a[key]?.localeCompare(b[key]);

          default:
            return a[key].value - b[key].value;
        }
      });
    }

    return filteredRepayments;
  };

  const handleSotByChange = ({
    value,
  }: OnChangeParams) => {
    dispatch(setSearchSortBy(value));
  };

  const handleStatusChange = ({
    value,
  }: OnChangeParams) => {
    dispatch(setSearchStatus(value));
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();

    dispatch(fetchRepayments({
      organizationID,
      pageNumber: page,
    }));
  };

  const openTPORepaymentAllocationModal = (repayment: RepaymentType) => {
    setSelectedRepayment(repayment);
    dispatch(setModal({
      name: ModalNames.TPO_REPAYMENT_ALLOCATION_MODAL,
      isOpen: true,
    }));
  };

  const handleTPORepaymentAllocationModalClose = () => {
    setSelectedRepayment(undefined);
    dispatch(setModal({
      name: ModalNames.REPAYMENT_EDIT_MODAL,
      isOpen: false,
    }));
  };

  const openRepaymentEditModal = (repayment: RepaymentType) => {
    setSelectedRepayment(repayment);
    dispatch(setModal({
      name: ModalNames.REPAYMENT_EDIT_MODAL,
      isOpen: true,
    }));
  };
  const handleEditModalClose = () => {
    setSelectedRepayment(undefined);
    dispatch(setModal({
      name: ModalNames.REPAYMENT_EDIT_MODAL,
      isOpen: false,
    }));
  };

  const saveRepayment = (id: RepaymentIDType, data: RepaymentUpdateType) => {
    dispatch(updateRepayment({ organizationID, id, data })).then(() => {
      handleEditModalClose();
    });
  };

  useEffect(() => {
    if (!prevPage.startsWith('/repayments')) {
      dispatch(fetchRepayments({ organizationID, pageNumber: '1' }));
      dispatch(resetRepaymentsSearch());
    } else if (prevPage.startsWith('/repayments') && (batchAllocationsCreated || repaymentSource === 'treasury')) {
      dispatch(fetchRepayments({ organizationID, pageNumber: '1' }));
      dispatch(resetBatchAllocationsCreated());
    }
    dispatch(setPrevPage(''));
    dispatch(setRepaymentSource(''));
  }, []);

  return (
    <div className={css(containerStyles)}>
      <Loader active={pendingList} />

      <Grid
        gridColumns={12}
      >
        <Cell span={12}>
          <b>
            {t('common:filterBy')}
          </b>
        </Cell>

        <CellFormControl
          cellSpan={[12, 6, 4]}
          label={`${t('repayments:repaymentFilterPaymentAllocation.label')}`}
        >
          <Select
            size={SIZE.compact}
            placeholder={t('common:select')}
            type="select"
            clearable
            options={statuses}
            labelKey="label"
            valueKey="value"
            onChange={handleStatusChange}
            value={status}
            overrides={{
              ControlContainer: {
                props: {
                  id: 'batch-repayment-payment-allocations-select',
                },
              },
            }}
          />
        </CellFormControl>

        <CellFormControl
          cellSpan={[12, 6, 4]}
          label={`${t('common:sortBy')}`}
        >
          <Select
            size={SIZE.compact}
            placeholder={t('common:select')}
            type="select"
            labelKey="label"
            valueKey="value"
            clearable
            options={sortByOptions}
            onChange={handleSotByChange}
            value={sortBy}
            overrides={{
              ControlContainer: {
                props: {
                  id: 'batch-repayment-sort-by-select',
                },
              },
            }}
          />
        </CellFormControl>
      </Grid>

      <Block overrides={blockOverrides}>
        <Grid>
          <Cell span={12}>
            {sortedRepayments()?.map((repayment: RepaymentType) => (
              <RepaymentListItem
                key={repayment?.id}
                repayment={repayment}
                openRepaymentEditModal={openRepaymentEditModal}
                openTPORepaymentAllocationModal={openTPORepaymentAllocationModal}
              />
            ))}

            {sortedRepayments()?.length > 0 && (
              <Block
                display="flex"
                width="100%"
                alignItems="center"
                justifyContent="center"
                justifyItems="center"
                marginBottom="16px"
              >
                <Pagination
                  size={SIZE.compact}
                  numPages={numPages}
                  currentPage={pageNumber}
                  overrides={paginationTransparentOverrides}
                  onPageChange={handlePageChange}
                />
              </Block>
            )}

            {sortedRepayments()?.length === 0 && (
              <Block
                marginTop="16px"
                marginBottom="16px"
              >
                <Card>
                  <p>{t('repayments:notFound')}</p>
                </Card>
              </Block>
            )}
          </Cell>
        </Grid>
      </Block>

      {checkIsModalOpen(modals, ModalNames.REPAYMENT_FORM_MODAL) && <RepaymentFormModal />}
      {checkIsModalOpen(modals, ModalNames.REPAYMENT_EDIT_MODAL) && selectedRepayment
      && (
      <RepaymentEditModal
        repayment={selectedRepayment}
        handleModalClose={handleEditModalClose}
        saveRepayment={saveRepayment}
      />
      )}
      {checkIsModalOpen(modals, ModalNames.TPO_REPAYMENT_ALLOCATION_MODAL) && selectedRepayment
      && (
      <TPORepaymentAllocationModal
        repayment={selectedRepayment}
        handleModalClose={handleTPORepaymentAllocationModalClose}
      />
      )}
    </div>
  );
};

export default memo(RepaymentRepaymentSection);
