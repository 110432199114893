import { Block } from 'baseui/block';
import { Card } from 'baseui/card';
import { Search } from 'baseui/icon';
import { Input } from 'baseui/input';
import { useStyletron } from 'styletron-react';
import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { Pagination } from 'baseui/pagination';
import {
  OnChangeParams,
  Select,
  SIZE,
  Value,
} from 'baseui/select';
import { LabelSmall } from 'baseui/typography';
import Loader from 'components/Loader';
import {
  ChangeEvent, memo, useEffect, useState,
} from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  batchesPageNumberSelector,
  batchesPendingListSelector,
  batchesSelector,
  batchesTPOSearchDateCreatedSelector,
  batchesTPOSearchPatternSelector,
  batchesTPOSearchPayGroupSelector,
  batchesTPOSearchPayPeriodSelector,
  batchesNumPagesSelector,
  fetchAllBatches,
  resetTPOBatchSearch,
  setTPOBatchSearchDateCreated,
  setTPOBatchSearchPattern,
  setTPOBatchSearchPayGroup,
  setTPOBatchSearchPayPeriod,
  batchesTotalSizeSelector,
  batchesTPOSearchBatchStatusSelector,
  setTPOSearchBatchStatus,
} from 'store/slices/batches';
import {
  blockOverrides,
  containerStyles,
} from 'screens/Batches/BatchesHelpers';
import {
  fetchPayGroups,
  fetchPayrollPeriods,
  payGroupPayrollPeriodsSelector,
  payGroupsSelector,
} from 'store/slices/payGroups';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  BatchItem,
  BatchStatuses,
  FetchAllBatchesParamsType,
} from 'types/BatchTypes';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import { DatePicker } from 'baseui/datepicker';
import {
  prevPageSelector,
  setPrevPage,
} from 'store/slices/application';
import { unScalePrice } from 'utils/priceScale';
import { ForwardedInput } from 'components/Form/AppDatePicker';
import {
  InputCustomHTMLElement,
  OptionalDateOrDateArrayType,
} from 'types/CommonTypes';
import TPOBatchesListItem from './TPOBatchesListItem/TPOBatchesListItem';

const TPOBatchesSection = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['batches', 'common', 'dateFormats']);
  const dispatch = useAppDispatch();
  const organization = useAppSelector(loggedOrganizationSelector);
  const batches = useAppSelector(batchesSelector);
  const pendingList = useAppSelector(batchesPendingListSelector);
  const payGroups = useAppSelector(payGroupsSelector);
  const payGroupPayrollPeriods = useAppSelector(payGroupPayrollPeriodsSelector);
  const payGroup = useAppSelector(batchesTPOSearchPayGroupSelector);
  const payrollPeriod = useAppSelector(batchesTPOSearchPayPeriodSelector);
  const creationDate = useAppSelector(batchesTPOSearchDateCreatedSelector);
  const search = useAppSelector(batchesTPOSearchPatternSelector);
  const batchStatus = useAppSelector(batchesTPOSearchBatchStatusSelector);
  const prevPage = useAppSelector(prevPageSelector);

  const pageNumber = useAppSelector(batchesPageNumberSelector);
  const numPages = useAppSelector(batchesNumPagesSelector);
  const totalSize = useAppSelector(batchesTotalSizeSelector);

  const { id: organizationID } = organization || {};
  const dateFormat = t('dateFormats:standard');
  const datePickerDateFormat = t('dateFormats:date-picker.standard');
  const datePickerPlaceholder = t('dateFormats:date-picker.placeholder');
  const dateFormatAPI = t('dateFormats:standard-reverse');

  const [searchPayGroupChanged, setSearchPayGroupHasChanged] = useState<boolean>(false);
  const [searchPayPeriodChanged, setSearchPayPeriodHasChanged] = useState<boolean>(false);
  const [searchCreationDateChanged, setSearchCreationDateHasChanged] = useState<boolean>(false);
  const [searchBatchStatusChanged, setSearchBatchStatusHasChanged] = useState<boolean>(false);

  const batchStatuses = [
    {
      id: 1,
      value: BatchStatuses.NEW,
      label: t(`batches:statuses.${BatchStatuses.NEW}`),
    },
    {
      id: 2,
      value: BatchStatuses.PARTIALLY_PAID,
      label: t(`batches:statuses.${BatchStatuses.PARTIALLY_PAID}`),
    },
    {
      id: 3,
      value: BatchStatuses.PAID_IN_FULL,
      label: t(`batches:statuses.${BatchStatuses.PAID_IN_FULL}`),
    },
    {
      id: 4,
      value: BatchStatuses.SENT_TO_PAYROLL,
      label: t(`batches:statuses.${BatchStatuses.SENT_TO_PAYROLL}`),
    },
    {
      id: 5,
      value: BatchStatuses.CANCELED,
      label: t(`batches:statuses.${BatchStatuses.CANCELED}`),
    },
    {
      id: 6,
      value: BatchStatuses.FAILED,
      label: t(`batches:statuses.${BatchStatuses.FAILED}`),
    },
  ];

  const datePickerOverridesExtended = (id: string) => ({
    Input: {
      props: {
        id,
        autoComplete: 'off',
        overrides: {
          Input: {
            component: ForwardedInput,
            props: {
              autoComplete: 'off',
            },
          },
        },
      },
    },
  });

  const hasSearchWordInBatches = (item: BatchItem) => {
    const searchWord = search?.trim().toLowerCase();

    if (searchWord) {
      const {
        batchReference,
        payrollPeriodStartDate,
        payrollPeriodEndDate,
        status,
        payGroupPayFrequencyRule,
        subElementsAmount,
      } = item || {};

      const normalizeString = (text: string | number) => text?.toString().trim().toLowerCase();

      const unscaledAmount = subElementsAmount ? unScalePrice(subElementsAmount?.value, subElementsAmount?.scale) : 0;

      return searchWord
        && (
          normalizeString(moment(payrollPeriodStartDate).format(dateFormat))?.includes(searchWord)
          || normalizeString(moment(payrollPeriodEndDate).format(dateFormat))?.includes(searchWord)
          || normalizeString(batchReference)?.includes(searchWord)
          || normalizeString(payGroupPayFrequencyRule)?.includes(searchWord)
          || normalizeString(status)?.includes(searchWord)
          || normalizeString(unscaledAmount)?.includes(searchWord)
        );
    }

    return true;
  };

  const filteredBatches = batches?.filter(hasSearchWordInBatches);

  const handleChangeSearch = (e: ChangeEvent<InputCustomHTMLElement>) => {
    dispatch(setTPOBatchSearchPattern(e.target.value));
  };

  const handlePayGroupChange = ({
    value,
  }: OnChangeParams) => {
    setSearchPayGroupHasChanged(true);
    dispatch(setTPOBatchSearchPayGroup(value));
    dispatch(setTPOBatchSearchPayPeriod([]));
  };

  const handlePayrollPeriodChange = ({
    value,
  }: OnChangeParams) => {
    setSearchPayPeriodHasChanged(true);
    dispatch(setTPOBatchSearchPayPeriod(value));
  };

  const handleCreationDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    if (date !== null || date !== undefined) {
      setSearchCreationDateHasChanged(true);
      if (date && !Array.isArray(date)) {
        dispatch(setTPOBatchSearchDateCreated(date as Date));
      } else if (!date) {
        dispatch(setTPOBatchSearchDateCreated(undefined));
      }
    }
  };

  const handleStatusChange = ({
    value,
  }: OnChangeParams) => {
    setSearchBatchStatusHasChanged(true);
    dispatch(setTPOSearchBatchStatus(value));
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();

    dispatch(fetchAllBatches({
      organizationID,
      pageNumber: page,
      payGroupId: (payGroup && Object.keys(payGroup).length !== 0 && payGroup[0].id?.toString()) || '',
      payrollPeriodId: (payrollPeriod && Object.keys(payrollPeriod).length !== 0 && payrollPeriod[0].id?.toString()) || '',
      status: (batchStatus && batchStatus[0]?.value?.toString()) || '',
      types: 'TCO',
    }));
  };

  useEffect(() => {
    const filter = new Map([
      ['organizationID', organizationID],
      ['pageNumber', '1'],
      ['payGroupId', (payGroup && Object.keys(payGroup).length !== 0 && payGroup[0].id?.toString()) || ''],
      ['payrollPeriodId', (payrollPeriod && Object.keys(payrollPeriod).length !== 0 && payrollPeriod[0].id?.toString()) || ''],
      ['status', (batchStatus && batchStatus[0]?.value?.toString()) || ''],
      ['types', 'TCO'],
    ]);

    if (creationDate) {
      filter.set('creationDate', moment(creationDate.toString()).format(dateFormatAPI));
    }
    if (searchPayGroupChanged || searchPayPeriodChanged || searchCreationDateChanged || searchBatchStatusChanged) {
      dispatch(fetchAllBatches(Object.fromEntries(filter) as unknown as FetchAllBatchesParamsType));
    }
  }, [
    creationDate,
    payGroup,
    payrollPeriod,
    batchStatus,
    searchPayGroupChanged,
    searchPayPeriodChanged,
    searchCreationDateChanged,
    searchBatchStatusChanged,
  ]);

  useEffect(() => {
    const statuses = 'OPEN, LOCKED, CLOSED';

    if (organizationID && payGroup && payGroup[0]?.id && searchPayGroupChanged) {
      dispatch(fetchPayrollPeriods({ organizationID, payGroupID: payGroup[0]?.id?.toString(), statuses }));
    }
  }, [organizationID, payGroup, searchPayGroupChanged]);

  useEffect(() => {
    if (!prevPage.startsWith('/batches')) {
      dispatch(fetchAllBatches({
        organizationID,
        pageNumber: '1',
        payGroupId: '',
        payrollPeriodId: '',
        creationDate: '',
        types: 'TCO',
      })).then(() => {
        dispatch(fetchPayGroups({ organizationID }));
      });
      dispatch(resetTPOBatchSearch());
    }
    dispatch(setPrevPage(''));
  }, []);

  return (
    <div className={css(containerStyles)}>
      <Loader active={pendingList} />
      <Grid gridColumns={12}>

        <Cell span={12}>
          <b>
            {t('common:searchBy')}
          </b>
        </Cell>

        <Cell
          span={[12, 12, 3]}
        >
          <Block
            marginTop="32px"
            display="flex"
            width="auto"
            marginBottom="20px"
          >
            <Input
              clearable
              startEnhancer={<Search />}
              type="text"
              name="search"
              autoComplete="off"
              onChange={handleChangeSearch}
              value={search}
              clearOnEscape
              placeholder={t('common:filterPlaceholder')}
              id="batch-management-search"
            />
          </Block>
        </Cell>

        <Cell
          span={[12, 6, 3]}
        >
          <Block
            display="flex"
            flexDirection="column"
            width="auto"
            marginBottom="20px"
          >
            <LabelSmall padding="8px 0px">{t('common:payGroup')}</LabelSmall>
            <Select
              maxDropdownHeight="300px"
              placeholder={t('common:select')}
              type="select"
              clearable
              options={payGroups
                ?.map(({ name, id }) => ({
                  name,
                  id,
                }))
                ?.sort((a, b) => a.name.localeCompare(b.name))}
              labelKey="name"
              valueKey="id"
              onChange={handlePayGroupChange}
              value={payGroup as Value}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'tpoBatches-pay-groups',
                    id: 'tpoBatches-pay-group-select',
                  },
                },
              }}
            />
          </Block>
        </Cell>

        <Cell
          span={[12, 6, 3]}
        >
          <Block
            display="flex"
            flexDirection="column"
            width="auto"
            marginBottom="20px"
          >
            <LabelSmall padding="8px 0px">{t('common:payPeriod')}</LabelSmall>
            <Select
              maxDropdownHeight="300px"
              placeholder={t('common:select')}
              type="select"
              clearable
              disabled={!payGroup || payGroup?.length === 0}
              options={payGroupPayrollPeriods?.map(({ startDate, endDate, id }) => ({
                name: `${moment(startDate).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`,
                id,
              }))}
              labelKey="name"
              valueKey="id"
              onChange={handlePayrollPeriodChange}
              value={payrollPeriod as Value}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'tpoBatches-pay-period',
                    id: 'tpoBatches-pay-period-select',
                  },
                },
              }}
            />
          </Block>
        </Cell>

        <Cell
          span={[12, 6, 3]}
        >
          <Block
            display="flex"
            flexDirection="column"
            width="auto"
            marginBottom="20px"
          >
            <LabelSmall padding="8px 0px">{t('batches:status.label')}</LabelSmall>
            <Select
              maxDropdownHeight="300px"
              placeholder={t('common:select')}
              type="select"
              clearable
              options={batchStatuses}
              onChange={handleStatusChange}
              value={batchStatus as Value}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'tpoBatches-management-status',
                    id: 'tpoBatches-management-status-select',
                  },
                },
              }}
            />
          </Block>
        </Cell>
      </Grid>

      <Grid gridColumns={12}>
        <Cell
          span={[12, 6, 3]}
        >
          <Block
            display="flex"
            width="auto"
            flexDirection="column"
            marginBottom="20px"
          >
            <LabelSmall padding="8px 0px">{t('batches:creationDate.label')}</LabelSmall>
            <DatePicker
              clearable
              placeholder={datePickerPlaceholder}
              formatString={datePickerDateFormat}
              mask={dateFormat}
              value={creationDate}
              onChange={handleCreationDateChange}
              overrides={datePickerOverridesExtended('TPOBatchesManagementSection-creationDate')}
            />
          </Block>
        </Cell>
      </Grid>
      <Block overrides={blockOverrides}>
        <Grid>
          <Cell span={12}>
            {filteredBatches?.map((batch: BatchItem) => (
              <TPOBatchesListItem key={batch?.id} batch={batch} search={search} />
            ))}

            {filteredBatches?.length > 0 && (
              <Block
                display="flex"
                width="100%"
                alignItems="center"
                justifyContent="center"
                justifyItems="center"
                marginBottom="16px"
              >
                <Pagination
                  size={SIZE.compact}
                  numPages={numPages}
                  currentPage={pageNumber}
                  overrides={paginationTransparentOverrides}
                  onPageChange={handlePageChange}
                />
              </Block>
            )}

            {filteredBatches?.length === 0 && (
              <Block
                marginTop="16px"
                marginBottom="16px"
              >
                <Card>
                  <p>{t('batches:notFound')}</p>
                </Card>
              </Block>
            )}
          </Cell>
        </Grid>
      </Block>
    </div>
  );
};

export default memo(TPOBatchesSection);
