import { borderRadius } from 'theme';

export const blockOverrides = {
  Block: {
    style: {
      background: '#F3F3F3',
      paddingTop: '20px',
      paddingBottom: '20px',
    },
  },
};

export const contentTableBuilderContainerStyles = {
  background: '#fff',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
  border: '1px solid #E2E2E2',
  'box-sizing': 'border-box',
  padding: '8px',
  width: '100%',
  height: '100%',
  marginTop: '16px',
};
