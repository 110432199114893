import { ReactNode } from 'react';
import { repaymentsInitialValues, tpoRepaymentsInitialValues } from 'initialValues/RepaymentsInitialValues';
import { MoneyDtoType } from 'types/CommonTypes';
import { LocationIDType } from './LocationTypes';
import { OrganizationIDType } from './OrganizationTypes';
import { PayGroupIDType, PayrollPeriodIDType } from './PayGroupTypes';
import { BatchIDType } from './BatchTypes';
import { EmploymentIDType } from './EmployeeTypes';

export enum PaymentStatusType {
  CREATED = 'CREATED',
  RECEIVED = 'RECEIVED',
  OPEN = 'OPEN',
  TO_BE_DISTRIBUTED = 'TO_BE_DISTRIBUTED',
  DISTRIBUTED = 'DISTRIBUTED',
  ALLOCATED = 'ALLOCATED',
  PARTIALLY_ALLOCATED = 'PARTIALLY_ALLOCATED',
  ACCEPTED = 'ACCEPTED',
  COMPLETED = 'COMPLETED'
}

export enum RepaymentTypesType {
  EWA = 'EWA',
  TPO = 'TCO'
}

export type RepaymentsFormFilterPropsType = {
  title?: string
  handleClickCancel: () => void
  handleClickSave: () => void
  buttonsDisabled: boolean
}

export type RepaymentIDType = string | number | null | undefined;

export type RepaymentsValuesType = typeof repaymentsInitialValues;

export type TPORepaymentsValuesType = typeof tpoRepaymentsInitialValues;

export type SaveRepaymentRequestType = {
  transactionId: string
  amount: MoneyDtoType
  id?: number
  allocatedAmount?: MoneyDtoType
  balance?: MoneyDtoType
  status?: PaymentStatusType
  dateReceived?: string
}

export type SaveRepaymentsParamsType = {
  organizationID?: OrganizationIDType
  data: SaveRepaymentRequestType
}

export type RepaymentType = {
  id: number,
  transactionId: string
  dateReceived: string
  balance: {
    currencyCode: string
    scale: number
    value: number
  },
  allocatedAmount: {
    currencyCode: string
    scale: number
    value: number
  },
  amount: {
    currencyCode: string
    scale: number
    value: number
  },
  type: string,
  status?: PaymentStatusType
}

export type PaymentAllocationHeaderPropsType = {
  children?: ReactNode
}

export type FetchRepaymentsParamsType = {
  organizationID: OrganizationIDType
  pageNumber: string
  locationID?: LocationIDType
  payGroupID?: PayGroupIDType
}

export type FetchRepaymentParamsType = {
  organizationId: OrganizationIDType
  repaymentId: RepaymentIDType
}

export type FetchBatchAllocationsParamsType = {
  organizationID: OrganizationIDType,
  pageNumber: string,
  payGroupId?: string,
  payrollPeriodId?: string
}
export type FetchAllocatedBatchAllocationsParamsType = {
  organizationID: OrganizationIDType,
  repaymentId: RepaymentIDType,
  pageNumber: string,
}

export type FetchBatchEmploymentsParamsType = {
  batchID: BatchIDType,
  pageNumber: string,
}
export type FetchAllocatedBatchEmploymentsParamsType = {
  batchID: BatchIDType,
  repaymentId: RepaymentIDType,
  pageNumber: string,
}
export type FetchAllocatedTPOBatchEmploymentsParamsType = {
  batchID: BatchIDType
}

export type FetchBatchEmploymentDrawAllocationsParamsType = {
  batchID: BatchIDType
  employmentID: EmploymentIDType
}

export type FetchAllocatedBatchEmploymentDrawAllocationsParamsType = {
  batchID: BatchIDType
  repaymentID: RepaymentIDType
  employmentID: EmploymentIDType
}

export type CreateDrawAllocationsRequestType = {
  allocations: {
    id: number
    amount: MoneyDtoType
  }[]
}

export type CreateBatchAllocationsRequestType = {
  allocations: {
    id: number
    amount: MoneyDtoType
  }[]
}

export type CreateEmploymentAllocationsRequestType = {
  allocations: {
    id: number
    amount: MoneyDtoType
  }[]
}

export type CreateDrawAllocationsParamsType = {
  batchID: BatchIDType
  repaymentID: RepaymentIDType
  employmentID: EmploymentIDType
  data: CreateDrawAllocationsRequestType
}

export type CreateBatchAllocationsParamsType = {
  repaymentID: RepaymentIDType
  data: CreateBatchAllocationsRequestType
}

export type CreateEmploymentAllocationsParamsType = {
  repaymentID: RepaymentIDType
  batchID: BatchIDType
  data: CreateEmploymentAllocationsRequestType
}

export type PaymentAllocationsFilterType = {
  payGroupID?: PayGroupIDType
  payrollPeriodID?: PayrollPeriodIDType
  search?: string
}

export type PaymentAllocationTablePropsType = {
  filter: PaymentAllocationsFilterType
}

export type RepaymentUpdateType = {
  transactionId: string,
  amount: MoneyDtoType,
  dateReceived: string
}

export type CreateTPOBatchAllocationRequestType = {
  transactionId: string,
  dateReceived: string
}

export enum KeyType {
  amount = 'amount',
  balance = 'balance',
  allocatedAmount = 'allocatedAmount',
  dateReceived = 'dateReceived',
  transactionId = 'transactionId',
}

export type AllocationBatchType = {
  id: number,
  reference: string,
  payrollPeriod: {
    id: number,
    name: string
    startDate: string
    endDate: string
  },
  payGroup: {
    id: number,
    name: string
  },
  amount: MoneyDtoType,
  amountDue: MoneyDtoType,
  allocatedAmount: MoneyDtoType
}

export type BatchEmploymentType = {
  id: number,
  workerId: string,
  status: string,
  organization: {
    id: number,
    name: string
  },
  payrollId: string,
  externalUserId: string,
  statusEffectiveDate: string,
  firstName: string,
  lastName: string,
  totalDrawAmount: MoneyDtoType,
  batchId: number
}

export type AllocatedBatchEmploymentType = {
  id: number,
  payrollId: string,
  firstName: string,
  lastName: string,
  allocatedAmount: MoneyDtoType,
}

export type AllocatedTPOBatchEmploymentType = {
  id: number,
  payrollId: string,
  firstName: string,
  lastName: string,
  totalAmount: MoneyDtoType,
  allocatedAmount: MoneyDtoType,
}

export type DrawAllocationType = {
  id: number
  createdDate: string
  allocatedAmount: MoneyDtoType
  totalAmount: MoneyDtoType
  amountDue: MoneyDtoType
}

export type TPOPaymentAllocationType = {
  id: number,
  createdDate: string,
  location: {
    id: number,
    name: string,
  },
  totalAmount: MoneyDtoType,
  amountDue: MoneyDtoType,
  allocatedAmount: MoneyDtoType,
}
