import {
  memo,
  useEffect,
  useState,
} from 'react';
import {
  Grid,
  Cell,
  ALIGNMENT,
} from 'baseui/layout-grid';
import Loader from 'components/Loader';
import { Block } from 'baseui/block';
import { Card } from 'baseui/card';
import CellFormControl from 'components/CellFormControl';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import DatePicker from 'react-datepicker';
import {
  fetchBankFiles,
  bankFilesSelector,
  pendingBankFilesSelector,
} from 'store/slices/transactions';
import AppStyledTable from 'components/AppStyledTable/AppStyledTable';
import {
  BankFileTypes,
  DailyBankFilesPropTypes,
  BankFileType,
} from 'types/BankFilesTypes';
import { headerStyle } from 'screens/CommonHelpers';
import { userAccessUnitsSelector } from 'store/slices/user';
import { AccessUnit } from 'components/Access/Access';
import { blockOverrides } from '../TreasuryHelper';

const rowStyles = {
  ...headerStyle,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whitSpace: 'nowrap',
};

const DailyBankFilesSection = ({
  fileType = BankFileTypes.CARD,
}: DailyBankFilesPropTypes) => {
  const { t } = useTranslation(['treasury', 'dateFormats']);
  const dispatch = useAppDispatch();
  const pending = useAppSelector(pendingBankFilesSelector);
  const files = useAppSelector(bankFilesSelector);
  const [year, setYear] = useState<Date>(new Date());
  const standardDateFormat = t('dateFormats:standard');
  const standardDateFormatWithTime = t('dateFormats:standard-with-time');
  const userRoles = useAppSelector(userAccessUnitsSelector);

  const handleYearChange = (date: Date) => {
    if (date) {
      setYear(date as Date);
      dispatch(fetchBankFiles({
        fileType,
        year: moment(date).format('yyyy'),
      }));
    }
  };

  useEffect(() => {
    if (userRoles.includes(AccessUnit.TreasuryManager)) {
      dispatch(fetchBankFiles({ fileType }));
    }
  }, []);

  return (
    <Block
      overrides={blockOverrides}
    >
      <Grid align={ALIGNMENT.center} gridGaps={20}>
        <Cell span={12}>
          <b>
            {t('common:searchBy')}
          </b>
        </Cell>
        <CellFormControl
          cellSpan={2}
          label={`${t('treasury:dailyBank.yearLabel')}`}
        >
          <DatePicker
            selected={year}
            onChange={handleYearChange}
            showYearPicker
            placeholderText={t('treasury:gsFilesYearText')}
            dateFormat="yyyy"
          />
        </CellFormControl>
        <Cell span={12}>
          <Loader active={pending} />
          {files?.length ? (
            <AppStyledTable
              tableGridTemplateColumns="minmax(300px, 70%) minmax(150px, 15%) minmax(200px, 15%)"
              headingCells={[
                <span style={headerStyle}>{t('treasury:dailyBank.nameHeader')}</span>,
                <span style={headerStyle}>{t('treasury:dailyBank.businessDateHeader')}</span>,
                <span style={headerStyle}>{t('treasury:dailyBank.dateSubmittedHeader')}</span>,
              ]}
              rows={files?.map((file: BankFileType, index: number) => ({
                id: `${file.name}-${index}`,
                cells: [
                  <span style={rowStyles}><a href={file.url}>{file.name}</a></span>,
                  <span style={rowStyles}>{moment(file.businessDate).format(standardDateFormat)}</span>,
                  <span style={rowStyles}>{moment(file.dateSubmitted).format(standardDateFormatWithTime)}</span>,
                ],
              })) || []}
            />
          ) : (
            <Block
              marginTop="8px"
              marginBottom="8px"
            >
              <Grid gridMargins={0}>
                <Cell span={12}>
                  <Card>
                    <p>{t('treasury:noFilesFound')}</p>
                  </Card>
                </Cell>
              </Grid>
            </Block>
          )}
        </Cell>
      </Grid>
    </Block>
  );
};

export default memo(DailyBankFilesSection);
